import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { TOAST_SOURCE, TYPE_TOAST } from 'sirc-lib';
import { TemporaryDemandService } from '../../../core/services/temporary-demand.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { TitleService, TitleState } from '../../../shared/service/title.service';

@Component({
  selector: 'app-dialog-create-demand',
  templateUrl: './dialog-create-demand.component.html',
  styleUrls: ['./dialog-create-demand.component.scss']
})
export class DialogCreateDemandComponent implements OnInit {

  public captchaSiteKey: string;

  public createForm: UntypedFormGroup;

  public isRequestSent = false;

  public urlPdfSalProtect: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly toasterService: ToasterSharedService,
    private readonly configService: ConfigurationService,
    private readonly temporaryDemandService: TemporaryDemandService,
    private readonly titleService: TitleService,
  ) { }

  ngOnInit(): void {
    this.captchaSiteKey = this.configService.getEnvironment().captcha.siteKey;
    this.createForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      recaptcha: new UntypedFormControl('', Validators.required),
    });
    this.urlPdfSalProtect = this.data.pdfSalarieProtegeLien;
  }

  /**
   * Submit the request to create a new demand
   */
  public submitCreationDemand(): void {
    this.isRequestSent = true;
    this.titleService.changeTitle(TitleState.DEMANDE_CREATE);

    // send request
    this.temporaryDemandService.initCreationDemande(
      this.emailFormControl.value,
      this.recaptchaFormControl.value,
    ).subscribe({
      next: (_) => {
        this.toasterService.create(
          TOAST_SOURCE.TELE,{
          type: TYPE_TOAST.SUCCES,
          title: 'Succès',
          body: 'Votre demande a bien été envoyée.',
      });
      },
      error: (error) => {
        this.titleService.changeTitle({ value: TitleState.SERVER_ERROR.value + TitleState.DEMANDE_CREATE.value });
        this.toasterService.create(
          TOAST_SOURCE.TELE,{
          type: TYPE_TOAST.ERREUR,
          title: error.statusText,
          body: error.error.message,
        });
      }
    });
  }

  /**
   * FormControl Getters
   */
  public get emailFormControl(): UntypedFormControl {
    return this.createForm.get('email') as UntypedFormControl;
  }

  public get recaptchaFormControl(): UntypedFormControl {
    return this.createForm.get('recaptcha') as UntypedFormControl;
  }

}
