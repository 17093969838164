import { JobName } from './job-name.model';
export class Batch {
  public id: number;
  public jobName: JobName;
  public startTime: Date;
  public endTime: Date;
  public status: string;

  constructor({
    id = null,
    jobName = new JobName(null),
    startTime = new Date(),
    endTime = new Date(),
    status = ''
  }) {
    this.id = id;
    this.jobName = jobName;
    this.startTime = startTime;
    this.endTime = endTime;
    this.status = status;
  }
}
