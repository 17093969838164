<div
  [attr.aria-label]="tooltipText"
  matTooltipPosition="after"
  [matTooltip]="tooltipText"
  [tabindex]="notFocusable ? -1 : 0"
>
  <mat-icon role="img" aria-hidden="true" class="ms-1 mat-primary">
    help
  </mat-icon>
</div>
