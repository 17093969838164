
export class Pays {
  id: number;
  code: string;
  intitule: string;
  indicatifTel: string;
  ordre: number;

  public constructor({
    id = null,
    code = '',
    intitule = '',
    indicatifTel = '',
    ordre = 0,
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.indicatifTel = indicatifTel;
    this.ordre = ordre;
  }
}
