import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Adresse } from '../../models/adresse.model';

export class AdresseFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private model: Adresse,
    private isDisabled: boolean,
  ) { }

  build(): UntypedFormGroup {
    return this.fb.group({
      id: this.fb.control(
        {
          value: this.model.id,
          disabled: true,
        }),

      voie: this.fb.control(
        {
          value: this.model.voie,
          disabled: this.isDisabled,
        },
        [
          Validators.required, Validators.maxLength(250)
        ],
      ),

      complement: this.fb.control(
        {
          value: this.model.complement,
          disabled: this.isDisabled,
        },
        [
          Validators.maxLength(250)
        ],
      ),

      infosEtranger: this.fb.control(
        {
          value: this.model.infosEtranger,
          disabled: this.isDisabled,
        },
        [
          Validators.maxLength(250)
        ],
      ),

      pays: this.fb.control(
        {
          value: this.model.pays,
          disabled: this.isDisabled,
        },
        [
          Validators.required
        ],
      ),

      localisation: this.fb.control(
        {
          value: this.model.localisation,
          disabled: this.isDisabled || (this.model && this.model.pays && this.model.pays.code !== 'FR'),
        },
        [
          Validators.required
        ],
      ),

      bp: this.fb.control(
        {
          value: this.model.bp,
          disabled: this.isDisabled,
        },
        [
          Validators.maxLength(250)
        ],
      ),
    });
  }
}
