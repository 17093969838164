import { Component, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'lib-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateFormComponent implements OnDestroy {

  @ViewChild(MatDatepicker)
  private datePicker: MatDatepicker<moment.Moment>;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public dateControlName: string;

  @Input()
  public datePickerFilter: (date: Date) => boolean;

  @Input()
  public label: string;

  @Input()
  public isRequired = false;

  @Input()
  public enableHelp = false;

  @Input()
  public tooltipText: string;

  @Input()
  public minDate: Date = moment().subtract(100, 'years').toDate();

  @Input()
  public maxDate: Date = moment().add(100, 'year').toDate();

  private currentMode: string;
  private currentMonth: string;
  private currentYear: number;
  private caption: HTMLTableCaptionElement;
  private subscription = new Subscription();
  private monthOrYearChange = new EventEmitter<void>();

  constructor() { }

  public get dateControl(): UntypedFormControl {
    return this.form.get(this.dateControlName) as UntypedFormControl;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Map moment value to YYYY-MM-DD format
   */
  public dateChange(event): void {
    const date = event.value ? event.value.format('YYYY-MM-DD') : null;
    this.dateControl.setValue(date);
  }

  /**
   * Add accesibility attribute aria-current to datepicker and caption to calendar table
   */
  public openDatePicker(): void {
    setTimeout(() => {
      this.setAriaCurrent();
      this.subscription.add(this.datePicker.viewChanged.subscribe(() => {
        this.setAriaCurrent();
      }));

      this.updateCaption();
    });
  }

  /**
   * Add aria-current attribute to datepicker for accessibility purpose (RGAA 7.1)
   */
  private setAriaCurrent(): void {
    const today = document.querySelector('.mat-calendar .mat-calendar-body-today');
    if (today) {
      today.setAttribute('aria-current', 'date');
    }
  }

  /**
   * Add or update datepicker caption with current selection to describe displayed table (RGAA 7.1)
   */
  private updateCaption(): void {
    // Get datepicker calendar to subscribe to state change
    // tslint:disable-next-line: no-string-literal
    const calendar = this.datePicker['_componentRef']['instance']['_calendar'];

    // Update caption if month or year change
    this.subscription.add(this.monthOrYearChange.subscribe(() => {
      if (this.currentMode === 'multi-year') {
        this.caption.textContent = `Tableau de sélection d'une année entre ${this.minDate.getFullYear()} et ${this.maxDate.getFullYear()}`;
      } else if (this.currentMode === 'year') {
        this.caption.textContent = `Tableau de sélection d'un mois pour l'année ${this.currentYear}`;
      } else {
        this.caption.textContent = `Tableau de sélection d'un jour pour le mois de ${this.currentMonth} et l'année ${this.currentYear}`;
      }
    }));

    this.subscription.add(this.datePicker.viewChanged.subscribe((view) => {
      this.currentMode = view;

      // Add caption when view changes
      if (!this.caption) {
        this.createCaption();
      }

      // Set state change to update text
      calendar.stateChanges.next();
    }));

    this.subscription.add(calendar.stateChanges.subscribe(() => {
      const activeDate = calendar.activeDate;

      this.currentMonth = activeDate.format('MMMM');
      this.currentYear = activeDate.year();

      this.monthOrYearChange.emit();
    }));

    // Initialize text
    if (!this.caption) {
      this.createCaption();
    }

    const date = calendar.activeDate;

    this.currentMonth = date.format('MMMM');
    this.currentYear = date.year();

    this.monthOrYearChange.emit();
  }

  private createCaption(): void {
    this.caption = document.querySelector<HTMLTableElement>('.mat-calendar-table').createCaption();
    this.caption.classList.add('cdk-visually-hidden');
  }
}
