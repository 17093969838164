<form [formGroup]="form">
  <mat-card appearance="outlined" formGroupName="anciennete" id="anciennete">
    <mat-card-content>
      <h3>Calcul de l'indemnité</h3>
      <!--Anciennete-->
      <section>
        <div class="d-flex">
          <h4 class="d-inline-block">
            Ancienneté du ou de la salarié(e) à la date envisagée de rupture
          </h4>
          <lib-sirc-icon
            [form]="anciennete"
            [tooltipText]="
              'L\'ancienneté du salarié doit être exactement calculée, en années et mois, à la date envisagée de la rupture du contrat de travail.'
            "
          >
          </lib-sirc-icon>
        </div>

        <div class="container-fluid row flex-container">
          <div class="col d-flex flex-wrap flex-item">
            <fieldset class="anciennete flex-grow-1">
              <legend id="year-label">
                Nombre d'années d'ancienneté
                <span aria-hidden="true" *ngIf="!anciennete.disabled">*</span>
              </legend>

              <div class="d-flex align-items-center w-100">
                <mat-slider
                  [max]="60"
                  [min]="0"
                  [step]="1"
                  aria-labelledby="year-label"
                  discrete
                  class="flex-grow-1"
                >
                  <input
                    matSliderThumb
                    formControlName="ancienneteAnnee"
                    value="1.5"
                    (change)="updateAncienneteAnneeInput()"
                    #ancienneteAnneeSlider
                    [value]="ancienneteAnneeValue"
                  />
                </mat-slider>

                <mat-form-field class="slider-input custom-input fixed-width" appearance="outline">
                  <input
                    #ancienneteAnneeInput
                    type="number"
                    matInput
                    min="0"
                    max="60"
                    formControlName="ancienneteAnnee"
                    aria-labelledby="year-label"
                    required
                    [value]="ancienneteAnneeValue"
                    (change)="updateAncienneteAnneeSlider()"
                  />
                  <span matSuffix>ans</span>

                  <mat-error *ngIf="ancienneteAnnee.hasError('required')">
                    <p>Ce champ est obligatoire</p>
                  </mat-error>
                  <mat-error *ngIf="ancienneteAnnee.hasError('pattern')">
                    <p>Le nombre d'années doit être un nombre entier</p>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      ancienneteAnnee.hasError('max') ||
                      ancienneteAnnee.hasError('min')
                    "
                  >
                    <p>Le nombre d'années doit être compris entre 0 et 60</p>
                  </mat-error>
                </mat-form-field>
              </div>
            </fieldset>
          </div>

          <div class="col d-flex flex-wrap flex-item">
            <fieldset class="anciennete flex-grow-1">
              <legend id="month-label">
                Nombre de mois d'ancienneté
                <span aria-hidden="true" *ngIf="!anciennete.disabled">*</span>
              </legend>

              <div class="d-flex align-items-center w-100">
                <mat-slider
                  #monthSlider
                  [max]="11"
                  [min]="0"
                  [step]="1"
                  aria-labelledby="month-label"
                  discrete
                  class="flex-grow-1"
                >
                  <input
                    matSliderThumb
                    formControlName="ancienneteMois"
                    value="1.5"
                    (change)="updateAncienneteMonthInput()"
                    #ancienneteMoisSlider
                    [value]="ancienneteMoisValue"
                  />
                </mat-slider>

                <mat-form-field class="slider-input custom-input fixed-width" appearance="outline">
                  <input
                    #ancienneteMoisInput
                    type="number"
                    matInput
                    min="0"
                    max="11"
                    formControlName="ancienneteMois"
                    aria-labelledby="month-label"
                    required
                    [value]="ancienneteMoisValue"
                    (change)="updateAncienneteMoisSlider()"
                  />
                  <span matSuffix>mois</span>

                  <mat-error *ngIf="ancienneteMois.hasError('required')">
                    <p>Ce champ est obligatoire</p>
                  </mat-error>
                  <mat-error *ngIf="ancienneteMois.hasError('pattern')">
                    <p>Le nombre de mois doit être un nombre entier</p>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      ancienneteMois.hasError('max') ||
                      ancienneteMois.hasError('min')
                    "
                  >
                    <p>Le nombre de mois doit être compris entre 0 et 11</p>
                  </mat-error>
                </mat-form-field>
              </div>
            </fieldset>
          </div>
        </div>
      </section>

      <!-- Dernier mois rémunéré -->
      <section id="dernier-mois">
        <div class="d-flex">
          <h4 class="d-inline-block">Dernier mois rémunéré</h4>
          <lib-sirc-icon
            [form]="nombreMois"
            [tooltipText]="
              'Ces informations servent à pré-remplir le tableau des salaires en évitant les erreurs de mois et d\'années. Vous devez indiquer : ' +
              '
            soit le dernier mois dont le salaire est connu à la date de signature de la demande d\'homologation si l\'ancienneté est supérieure à un an; ' +
              '
            soit celui correspondant à la fin du contrat de travail. ' +
              '
            Par défaut, le montant saisi est automatiquement reporté sur les 12 derniers mois et peut être modifié directement dans la case correspondante.'
            "
          >
          </lib-sirc-icon>
        </div>
        <div class="container-fluid row">
          <fieldset class="d-flex no-padding">
            <div class="col-2 px-1 custom-width">
              <mat-form-field class="custom-input fixed-width" appearance="outline">
                <mat-label>Mois</mat-label>

                <mat-select formControlName="moisDernierMois" required>
                  <mat-option
                    *ngFor="let month of MOIS"
                    [value]="month.valeur"
                    [title]="month.nom"
                  >
                    {{ month.nom }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="moisDernierMois.hasError('required')">
                  <p>Ce champ est obligatoire</p>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-2 px-1 custom-width custom-margin">
              <mat-form-field class="custom-input fixed-width" appearance="outline">
                 <mat-label>Années</mat-label>

                <mat-select formControlName="anneeDernierMois" required>
                  <mat-option
                    *ngFor="let year of listYears"
                    [value]="year"
                    [title]="year"
                  >
                    {{ year }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="anneeDernierMois.hasError('required')">
                  <p>Ce champ est obligatoire</p>
                </mat-error>
              </mat-form-field>
            </div>
          </fieldset>
        </div>
      </section>
    </mat-card-content>
    <mat-card-actions *ngIf="!isSalaireDisplayed" class="align-end">
      <button
        mat-button
        class="w-100"
        [disabled]="anciennete.invalid"
        id="anciennete-to-salaire"
        (click)="scrollFromAncienneteToSalaire()"
        aria-label="Passer à l'étape de la rémunération"
      >
        <mat-icon aria-hidden="true">arrow_downward</mat-icon>
        Passer à l'étape suivante
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card
    appearance="outlined"
    *ngIf="isSalaireDisplayed"
    class="mt-2"
    formGroupName="salaire"
  >
    <mat-card-actions>
      <button
        mat-button
        id="salaire-to-anciennete"
        class="mat-primary w-100"
        (click)="scrollFromSalaireToAnciennete()"
        [hidden]="isResultDisplayed"
        aria-label="Retourner à l'étape de l'ancienneté"
      >
        <mat-icon aria-hidden="true">arrow_upward</mat-icon>
        Modifier l'étape précédente
      </button>
    </mat-card-actions>

    <!--Rémunération brute des 12 derniers mois-->
    <mat-card-content>
      <div class="d-flex">
        <h4 class="d-inline-block">Rémunération brute des 12 derniers mois</h4>

        <lib-sirc-icon
          [form]="salaire"
          [tooltipText]="
            'Vous devez indiquer les douze salaires précédant la date de signature de la demande d\'homologation afin de permettre la détermination de la base de calcul de l\'indemnité spécifique de rupture conventionnelle ; à défaut, la demande sera irrecevable.
        Il s\'agit des salaires bruts versés au salarié. En cas d\'absence pour maladie, vous devez indiquer le salaire brut mensuel moyen habituel (salaire reconstitué).'
          "
        >
        </lib-sirc-icon>
      </div>
      <div class="container-fluid remuneration">
        <fieldset>
          <legend id="label-remuneration">
            Rémunération brute des N derniers mois :
          </legend>

          <div class="d-flex flex-wrap">
            <mat-radio-group
              aria-labelledby="label-remuneration"
              formControlName="isIdentique"
              (change)="updateSalaryForm()"
            >
              <mat-radio-button class="me-2" [value]="true"
                >Identique sur les 12 derniers mois</mat-radio-button
              >
              <mat-radio-button [value]="false"
                >Evolutive sur les 12 derniers mois</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </fieldset>

        <fieldset class="d-flex flex-wrap w-100">
          <div
            class="row mt-2 w-100"
            *ngIf="isIdentique.value === true; else differentSalaire"
            formArrayName="valeurs"
          >
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label
                  >Rémunération mensuelle brute des 12 derniers mois</mat-label
                >

                <input
                  matInput
                  type="number"
                  step=".01"
                  formControlName="1"
                  (blur)="applySameSalaire($event.target.value)"
                  (change)="formatValue($event.target)"
                  required
                />
                <span aria-hidden="true" matSuffix class="euro-symbol">€</span>

                <mat-error *ngIf="valeurs.controls[1].hasError('required')">
                  <p>Ce champ est obligatoire</p>
                </mat-error>
                <mat-error
                  *ngIf="
                    !valeurs.controls[1].hasError('required') &&
                    valeurs.controls[1].hasError('pattern')
                  "
                >
                  <p>Numérique uniquement</p>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <ng-template #differentSalaire>
            <div class="row mt-2 w-100" formArrayName="valeurs">
              <div
                class="col-3"
                *ngFor="let mr of moisAnneeRemuneration; let i = index"
              >
                <mat-form-field appearance="outline">
                  <mat-label>{{ mr.mois }} {{ mr.annee }}</mat-label>

                  <input
                    matInput
                    type="number"
                    [formControlName]="i"
                    (change)="formatValue($event.target)"
                    step=".01"
                    required
                  />

                  <span aria-hidden="true" matSuffix class="euro-symbol"
                    >€</span
                  >
                  <mat-error
                    class="px-1"
                    *ngIf="valeurs.controls[i].hasError('required')"
                  >
                    <p>Ce champ est obligatoire</p>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      !valeurs.controls[i].hasError('required') &&
                      valeurs.controls[i].hasError('pattern')
                    "
                  >
                    <p>Numérique uniquement</p>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-template>

          <div class="row w-100">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>Dont primes</mat-label>

                <input
                  matInput
                  type="number"
                  step=".01"
                  formControlName="prime"
                  (change)="formatValue($event.target)"
                />

                <span aria-hidden="true" matSuffix class="euro-symbol-2"
                  >€</span
                >

                <lib-sirc-icon
                  matSuffix
                  class="info-icon"
                  [form]="prime"
                  [tooltipText]="
                    'Si le salarié a perçu des primes ou gratifications exceptionnelles au cours des 3 derniers mois (primes annuelles notamment), la loi prévoit de lisser ces sommes au prorata sur les autres mois. Vous devez donc déclarer ces primes à la fois sur le mois de leur versement (en complément de la rémunération habituelle) et de manière isolée, dans la case prévue à cet effet, pour permettre le calcul automatique de la rémunération mensuelle moyenne.'
                  "
                >
                </lib-sirc-icon>

                <mat-error *ngIf="prime.hasError('pattern')">
                  <p>Numérique uniquement</p>
                </mat-error>

                <mat-hint
                  *ngIf="!prime.hasError('pattern')"
                  class="d-flex bold italic text-muted hint-form"
                >
                  <p>
                    Dont total des primes annuelles et exceptionnelles au cours
                    des 3 mois les plus récents
                  </p>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
    </mat-card-content>

    <mat-card-actions *ngIf="!isResultDisplayed" class="align-end">
      <button
        mat-button
        class="w-100"
        [disabled]="salaire.invalid"
        id="salaire-to-result"
        (click)="scrollFromSalairetoResult()"
        aria-label="Passer à l'étape de la rémunération"
      >
        <mat-icon aria-hidden="true">arrow_downward</mat-icon>
        Passer à l'étape suivante
      </button>
    </mat-card-actions>
  </mat-card>

  <div *ngIf="isResultDisplayed">
    <mat-card appearance="outlined" class="mt-2" formGroupName="results">
      <mat-card-actions>
        <button
          mat-button
          class="mat-primary"
          id="result-to-salaire"
          (click)="scrollFromResultToSalaire()"
          [hidden]="form.disabled"
          aria-label="Retourner à l'étape de la rémunération"
        >
          <mat-icon aria-hidden="true">arrow_upward</mat-icon>
          Modifier l'étape précédente
        </button>
      </mat-card-actions>

      <mat-card-content>
        <section id="remuneration-moyenne">
          <h4>Rémunération mensuelle brute moyenne</h4>

          <div
            class="d-flex flex-row justify-content-between"
            [ngClass]="{ 'mb-1': isSimulatorMode }"
          >
            <div class="col-5 px-1" *ngIf="isEditionMode">
              <mat-form-field appearance="outline">
                <mat-label
                  >Moyenne la plus élevée entre les 12 ou 3 derniers
                  mois</mat-label
                >

                <input
                  matInput
                  type="number"
                  step=".01"
                  formControlName="moyenneRemunerationMensuelle"
                  (change)="formatValue($event.target)"
                  required
                />

                <span aria-hidden="true" matSuffix class="euro-symbol-2"
                  >€</span
                >

                <lib-sirc-icon
                  matSuffix
                  class="info-icon"
                  [form]="moyenneRemunerationMensuelle"
                  [tooltipText]="
                    'Moyenne utilisée pour votre calcul (montant éventuellement différent du calcul automatique si votre convention collective prévoit que la moyenne se calcule selon d\'autres modalités).'
                  "
                >
                </lib-sirc-icon>

                <mat-error
                  *ngIf="moyenneRemunerationMensuelle.hasError('required')"
                >
                  <p>Ce champ est obligatoire</p>
                </mat-error>

                <mat-error
                  *ngIf="
                    !moyenneRemunerationMensuelle.hasError('required') &&
                    moyenneRemunerationMensuelle.hasError('pattern')
                  "
                >
                  <p>Numérique uniquement</p>
                </mat-error>

                <mat-hint
                  *ngIf="
                    isMoyenneRemunerationMensuelleLowerThanAuto &&
                    !moyenneRemunerationMensuelle.hasError('pattern')
                  "
                  class="bold italic"
                >
                  <p class="warning">
                    La moyenne renseignée est inférieure au résultat du calcul
                    automatique
                  </p>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="info" [ngClass]="{ 'col-6': isEditionMode }">
              <p class="bold">
                Résultat du calcul automatique (moyenne) :
                {{
                  moyenneRemunerationMensuelleAutomatique.value
                    | number : ".2" : "fr"
                }}
                €
              </p>
              <p class="italic">
                Ce résultat ne tient pas compte des situations particulières
                (notamment alternance de périodes à temps complet et de périodes
                à temps partiels..) qui conduisent à un calcul différent de
                l'indemnité.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h4>
            Montant brut de l'indemnité spécifique de rupture conventionnelle
            proposé
          </h4>

          <div role="group">
            <div
              class="d-flex flex-row justify-content-between"
              [ngClass]="{ 'mb-1': isSimulatorMode }"
            >
              <div class="col-5 px-1" *ngIf="isEditionMode">
                <mat-form-field appearance="outline">
                  <mat-label>Indemnité proposée</mat-label>

                  <input
                    matInput
                    type="number"
                    step=".01"
                    formControlName="indemnite"
                    (change)="formatValue($event.target)"
                    required
                  />

                  <span aria-hidden="true" matSuffix class="euro-symbol-2"
                    >€</span
                  >

                  <lib-sirc-icon
                    matSuffix
                    class="info-icon"
                    [form]="indemnite"
                    [tooltipText]="
                      'Le montant de l\'indemnité spécifique de rupture conventionnelle ne peut être inférieur à celui de l\'indemnité légale de licenciement ou, à celui de l\'indemnité de licenciement prévue par la convention collective, dès lors que cette dernière est supérieure à l\'indemnité légale. Toutefois, certaines entreprises sont exclues de cette dernière obligation (secteur agricole, de l\'économie sociale, sanitaire et social…). Dans le cas où le salarié a moins d\'un an d\'ancienneté, l\'indemnité spécifique de rupture conventionnelle est due au prorata du nombre de mois de présence.'
                    "
                  >
                  </lib-sirc-icon>
                  <mat-error *ngIf="indemnite.hasError('required')">
                    <p>Ce champ est obligatoire</p>
                  </mat-error>

                  <mat-error
                    *ngIf="
                      !indemnite.hasError('required') &&
                      indemnite.hasError('pattern')
                    "
                  >
                    <p>Numérique uniquement</p>
                  </mat-error>

                  <mat-hint
                    *ngIf="
                      !indemnite.hasError('required') &&
                      !indemnite.hasError('pattern')
                    "
                    class="bold italic"
                  >
                    <p *ngIf="isIndemnityLowerThanConventional" class="warning">
                      L'indemnité spécifique renseignée est inférieure au
                      minimum conventionnel
                    </p>
                    <p *ngIf="isIndemnityLowerThanLegal" class="warning">
                      L'indemnité spécifique renseignée est inférieure au
                      minimum légal
                    </p>
                    <p>
                      Indemnité proposée (en lettres) :
                      {{ getIndemniteEnLettres() }}
                    </p>
                    <p class="warning">
                      Attention : le montant s'exprime avec deux chiffres après
                      la virgule
                    </p>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="info" [ngClass]="{ 'col-6': isEditionMode }">
                <p class="italic">
                  Pour un ou une salariée ayant l'ancienneté et les
                  rémunérations indiquées ci-dessus, le montant minimal
                  correspondant à <span class="bold">l'indemnité légale</span>
                  s'élève à (montant brut) :
                  <span class="bold"
                    >{{ indemniteLegale?.value | number : ".2" : "fr" }} €</span
                  >
                </p>
              </div>
            </div>

            <div
              class="d-flex flex-row align-items-center"
              *ngIf="isEditionMode"
            >
              <div class="col-5 px-1">
                <mat-form-field appearance="outline">
                  <mat-label
                    >Indemnité conventionnelle (éventuellement)</mat-label
                  >

                  <input
                    matInput
                    type="number"
                    step=".01"
                    formControlName="indemniteConventionnelle"
                    (change)="formatValue($event.target)"
                  />

                  <span aria-hidden="true" matSuffix class="euro-symbol"
                    >€</span
                  >
                  <mat-error
                    *ngIf="indemniteConventionnelle.hasError('pattern')"
                  >
                    <p>Numérique uniquement</p>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-7 px-1" *ngIf="isOnBo">
                <button
                  class="me-1"
                  mat-button
                  (click)="demandeTelechargerIndemConv()"
                  [disabled]="downloadLinkDisabled"
                >
                  <mat-icon aria-hidden="true">file_download</mat-icon>
                  Télécharger le fichier des indemnités conventionnelles
                </button>

                <mat-progress-bar
                  *ngIf="isDownloading"
                  class="mt-1"
                  mode="indeterminate"
                ></mat-progress-bar>
              </div>
            </div>

            <p class="italic" class="alert alert-warning" *ngIf="!isOnBo">
              Cette estimation ne porte que sur l'indemnité légale. Assurez-vous
              que la convention collective applicable ne prévoit pas le
              versement d'une indemnité de rupture plus élevée que le minimum
              légal et que l'employeur n'est pas alors tenu de verser cette
              indemnité conventionnelle. Un contrôle du respect du minimum
              conventionnel sera en effet effectué lors de l'instruction de la
              demande d'homologation par le service compétent.
            </p>
          </div>
        </section>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mt-2" *ngIf="isEditionMode">
      <div class="d-flex">
        <h4 class="d-inline-block">Commentaires</h4>
        <lib-sirc-icon
          [form]="commentaire"
          [tooltipText]="
            'Ce champ permet d\'apporter des précisions sur la situation du salarié, susceptibles d\'avoir un impact sur le calcul de l\'indemnité de rupture.
          Vous pouvez notamment préciser que les salaires indiqués pour certains mois ont été reconstitués à hauteur du salaire habituel compte tenu de la situation particulière du salarié (maladie, maternité…) ou que, pour le calcul de l\'indemnité, vous avez tenu compte du fait que le salarié avait travaillé à temps partiel ou à temps plein sur une certaine période.
          Ces informations permettront au service instructeur d\'examiner la demande en tenant compte de ces éléments et de limiter ainsi les cas de refus.'
          "
        >
        </lib-sirc-icon>
      </div>

      <mat-form-field appearance="outline" class="container-fluid">
        <mat-label>Commentaires</mat-label>

        <textarea
          matInput
          formControlName="commentaire"
          data-maxlength="10000"
        ></textarea>

        <mat-error *ngIf="commentaire.hasError('maxlength')">
          <p>Taille maximale : 10 000 caractères</p>
        </mat-error>
        <mat-hint class="bold italic">
          <p>{{ nombreChar }} caractères restants (10000 caractères maximum)</p>
        </mat-hint>
      </mat-form-field>
    </mat-card>
  </div>
</form>
