import { OrganismeMiniDto } from './organisme-mini-dto.dto';
import { Region } from './region.model';

export class Departement {
  id: number;
  code: string;
  intitule: string;
  region: Region;
  organismeReferent: OrganismeMiniDto;

  public constructor({
    id = null,
    code = '',
    intitule = '',
    region = new Region({}),
    organismeReferent = new OrganismeMiniDto({}),
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.region = region;
    this.organismeReferent = organismeReferent;
  }
}


