export class Signature {
  id: number;
  contenu: string;
  nom: string;
  principale: boolean;

  public constructor({
    id = null,
    contenu = '',
    nom = '',
    principale = false,
  }) {
    this.id = id;
    this.contenu = contenu;
    this.nom = nom;
    this.principale = principale;
  }
}


