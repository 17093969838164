export class ConventionCollective {
  id: number;
  actif: boolean;
  codeIdcc: string;
  intitule: string;
  motsCles: string;

  constructor({
    id = null,
    actif = true,
    codeIdcc = '',
    intitule = '',
    motsCles = '',
  }) {
    this.id = id;
    this.actif = actif;
    this.codeIdcc = codeIdcc;
    this.intitule = intitule;
    this.motsCles = motsCles;
  }
}


