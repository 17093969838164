import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { debounceTime, finalize } from 'rxjs/operators';
import { TYPE_QUALITE } from '../../../constants/referentiel/type-qualite.enum';
import { TYPE_TOAST } from '../../../constants/type-toast.enum';
import { EntretienFormBuilder } from '../../../forms/builder/entretien-form.builder';
import { CalculatedDates } from '../../../models/calculated-dates.model';
import { Entretien } from '../../../models/entretien.model';
import { QualiteAssistant } from '../../../models/qualite-assistant.model';
import { LibHttpDemandeService } from '../../../services/provided/lib-http-demande.service';
import { DemandeSharedService } from '../../../services/shared/demande-shared.service';
import { ToasterSharedService } from '../../../services/shared/toaster-shared.service';
import { LocalisationUtils } from '../../../utils/localisation.utils';
import { TOAST_SOURCE } from '../../../constants/toast-source.enum';

@Component({
  selector: 'lib-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.scss']
})
export class CalendrierComponent implements OnInit {

  public form: UntypedFormGroup;

  public parentForm: UntypedFormGroup;

  public listeQualiteAssistantSalarie: QualiteAssistant[];

  public listeQualiteAssistantEmployeur: QualiteAssistant[];

  public displayDates = false;

  public showSpinner = false;

  // True for IntraRC, False for TeleRC
  public isBo: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private demandeSharedService: DemandeSharedService,
    @Inject('LibHttpDemandeService')
    private demandeService: LibHttpDemandeService,
    private datePipe: DatePipe,
    private toasterService: ToasterSharedService,
  ) { }

  ngOnInit(): void {
    this.parentForm = this.demandeSharedService.form;
    this.form = this.demandeSharedService.form.get('calendrier') as UntypedFormGroup;

    this.isBo = this.demandeService.isOnBo();

    const listeQualites = this.demandeSharedService.resolvedData.qualites;
    this.listeQualiteAssistantEmployeur = listeQualites.filter(qualite => qualite.typeQa === TYPE_QUALITE.EMPLOYEUR);
    this.listeQualiteAssistantSalarie = listeQualites.filter(qualite => qualite.typeQa === TYPE_QUALITE.SALARIE);

    this.dateSignature.valueChanges.pipe(debounceTime(100)).subscribe(() => this.calculateDates());
    // Recalculate dates for TeleRC when adresse changes
    if (!this.isBo) {
      this.employeurAdresse.valueChanges.pipe(debounceTime(100)).subscribe(() => this.calculateDates());
      this.salarieAdresse.valueChanges.pipe(debounceTime(100)).subscribe(() => this.calculateDates());
    } else {
      // Recalculate dates for IntraRc when attributted organisme changes
      this.organismeAttribution.valueChanges.pipe(debounceTime(100)).subscribe(() => this.calculateDates());
      this.dateReception.valueChanges.pipe(debounceTime(100)).subscribe(() => this.calculateDates());
    }

    // show calculated bloc is dateFinDelaiRetractation and dateSignature is valid (fill the form) or disabled (after print)
    this.displayDates = (this.dateSignature.valid || this.dateSignature.disabled) && !!this.dateFinDelaiRetractation.value;
  }

  /**
   * Teletransmission date is one day after the deadine of withdrawal
   */
  getDateEnvoiFormulaire(): moment.Moment {
    if (this.dateFinDelaiRetractation?.value) {
      return moment(this.dateFinDelaiRetractation.value).add(1, 'days');
    }
    return null;
  }

  /**
   * End of contract is one day after the deadline of instruction
   */
  getLendemainDateFinDelaiInstruction(): moment.Moment {
    if (this.dateFinDelaiInstruction?.value) {
      return moment(this.dateFinDelaiInstruction.value).add(1, 'days');
    }
    return null;
  }

  /**
   * Calculate dealine of withdrawal and instruction by call the service
   */
  calculateDates(): void {
    const signature = this.dateSignature.value;

    // Calculate dates for TeleRC
    if (!this.isBo) {
      const localisation = LocalisationUtils.getLocalisation(this.employeurAdresse, this.salarieAdresse);

      if (localisation && signature) {
        this.showSpinner = true;

        this.demandeService.calculateDatesForFo(
          this.datePipe.transform(signature, 'dd/MM/yyyy'),
          localisation.codePostal,
          localisation.numeroInsee)
          .pipe(
            finalize(() => this.showSpinner = false),
          ).subscribe({
            next: (dates: CalculatedDates) => {
              this.dateFinDelaiRetractation.setValue(dates.dateFinDelaiRetractation);
              this.dateFinDelaiInstruction.setValue(dates.dateFinDelaiInstruction);
              this.displayDates = true;
            },
            error: () => {
              this.toasterService.create(
                TOAST_SOURCE.LIB,{
                type: TYPE_TOAST.ERREUR,
                title: 'Calcul des dates',
                body: 'Une erreur est survenue lors du calcul des dates',
              });
            }
          });
      } else {
        this.displayDates = false;
      }
    } else {
      // Calculate dates for IntraRC
      const department = this.organismeAttribution.value ? this.organismeAttribution.value : this.organismeReception.value;
      const reception = this.dateReception.value;
      if (department && department.id && signature && reception) {
        this.showSpinner = true;
        this.demandeService.calculateDatesForBo(
          this.datePipe.transform(signature, 'dd/MM/yyyy'),
          this.datePipe.transform(reception, 'dd/MM/yyyy'),
          department.id).pipe(
            finalize(() => this.showSpinner = false),
          ).subscribe((dates: CalculatedDates) => {
            this.dateFinDelaiRetractation.setValue(dates.dateFinDelaiRetractation);
            this.dateFinDelaiInstruction.setValue(dates.dateFinDelaiInstruction);
            this.displayDates = true;
          },
            () => {
              this.toasterService.create(
                TOAST_SOURCE.LIB,{
                type: TYPE_TOAST.ERREUR,
                title: 'Calcul des dates',
                body: 'Une erreur est survenue lors du calcul des dates',
              });
            }
          );
      } else {
        this.displayDates = false;
      }
    }
  }

  /** ENTRETIENS */
  ajouterEntretien(): void {
    this.entretiens.push(new EntretienFormBuilder(
      this.fb,
      new Entretien({}),
      false,
    ).build());
  }

  supprimerEntretien(index: number): void {
    this.entretiens.removeAt(index);
  }

  /**
   * Change the assisted emplyee option for the given interview
   */
  salarieAssisteChange(entretien: UntypedFormGroup, event): void {
    if (event.value) {
      entretien.get('nomAssistantSalarie').enable();
      entretien.get('qualiteAssistantSalarie').enable();
    } else {
      entretien.get('nomAssistantSalarie').disable();
      entretien.get('qualiteAssistantSalarie').disable();
    }
  }

  /**
   * Change the assisted emplyer option for the given interview
   */
  employeurAssisteChange(entretien: UntypedFormGroup, event): void {
    if (event.value) {
      entretien.get('nomAssistantEmployeur').enable();
      entretien.get('qualiteAssistantEmployeur').enable();
    } else {
      entretien.get('nomAssistantEmployeur').disable();
      entretien.get('qualiteAssistantEmployeur').disable();
    }
  }

  public compareFunction(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  ///// FORMULAIRE - GETTERS
  get id(): UntypedFormControl { return this.form.get('id') as UntypedFormControl; }
  get remarqueEntretiens(): UntypedFormControl { return this.form.get('remarqueEntretiens') as UntypedFormControl; }
  get dateSignature(): UntypedFormControl { return this.form.get('dateSignature') as UntypedFormControl; }
  get dateFinDelaiRetractation(): UntypedFormControl { return this.form.get('dateFinDelaiRetractation') as UntypedFormControl; }
  get dateFinDelaiInstruction(): UntypedFormControl { return this.form.get('dateFinDelaiInstruction') as UntypedFormControl; }
  get dateEnvisageeRupture(): UntypedFormControl { return this.form.get('dateEnvisageeRupture') as UntypedFormControl; }
  get dateTeletransmission(): UntypedFormControl { return this.form.get('dateTeletransmission') as UntypedFormControl; }
  get autresClauses(): UntypedFormControl { return this.form.get('autresClauses') as UntypedFormControl; }
  get entretiens(): UntypedFormArray { return this.form.get('entretiens') as UntypedFormArray; }
  isSalarieAssiste(entretien: UntypedFormGroup): boolean { return entretien.get('salarieAssiste').value; }
  isEmployeurAssiste(entretien: UntypedFormGroup): boolean { return entretien.get('employeurAssiste').value; }
  get employeurAdresse(): UntypedFormGroup { return this.parentForm.get('employeur').get('adresse') as UntypedFormGroup; }
  get salarieAdresse(): UntypedFormGroup { return this.parentForm.get('salarie').get('adresse') as UntypedFormGroup; }
  get organismeAttribution(): UntypedFormGroup { return this.parentForm.get('gestion').get('organismeAttribution') as UntypedFormGroup; }
  get organismeReception(): UntypedFormGroup { return this.parentForm.get('gestion').get('organismeReception') as UntypedFormGroup; }
  get dateReception(): UntypedFormGroup { return this.parentForm.get('gestion').get('dateReception') as UntypedFormGroup; }
}
