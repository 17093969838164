import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Localisation } from '../../models/localisation.model';
import { HttpAdresseService } from '../../services/http/http-adresse.service';

@Component({
  selector: 'lib-autocomplete-commune',
  templateUrl: './autocomplete-commune.component.html',
  styleUrls: ['./autocomplete-commune.component.scss']
})
export class AutocompleteCommuneComponent implements OnInit, OnDestroy {

  @Input()
  public minSize = 2;

  @Input()
  public delay = 500;

  @Input()
  public formLocalisation: UntypedFormGroup;

  public responseVille: Observable<Localisation[]>;
  public formQuery: UntypedFormGroup; // Search fields
  public loadingAutocomplete = false; // To display progress bar

  private selectedVille = false;
  private destroy$ = new Subject<void>();

  constructor(
    private adresseService: HttpAdresseService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.formQuery = this.formBuilder.group({
      codePostal: this.formBuilder.control('', [Validators.required]),
      commune: this.formBuilder.control('', [Validators.required]),
    });

    this.responseVille = this.formQuery.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter((query) =>
        // Start send search request when input is longer than minimum size
        query.codePostal && query.codePostal.length >= this.minSize || query.commune && query.commune.length >= this.minSize
      ),
      debounceTime(this.delay),
      tap(() => this.loadingAutocomplete = true),
      map(() => this.adresseService.villeAutocomplete(this.removeNull(this.formQuery.getRawValue())).pipe(
        finalize(() => this.loadingAutocomplete = false),
        catchError(() => of([]))
      )),
      mergeMap((address) => address),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public get codePostalControl(): UntypedFormControl {
    return this.formQuery.get('codePostal') as UntypedFormControl;
  }

  public get communeControl(): UntypedFormControl {
    return this.formQuery.get('commune') as UntypedFormControl;
  }

  /**
   * Select one option, patch formLocalisation first to avoid the comparation error in valueChanges method
   */
  public selectVille(event: MatAutocompleteSelectedEvent): void {
    this.selectedVille = true;
    const selectedVille: Localisation = event.option.value;

    if (selectedVille) {
      this.formLocalisation.patchValue(selectedVille);
      this.formQuery.patchValue(selectedVille);
    }
  }

  /**
   * User input changes
   */
  public startInput(): void {
    this.selectedVille = false;
  }

  /**
   * If no option selected, empty the inputs
   */
  public closeSelect(): void {
    if (!this.selectedVille) {
      this.formLocalisation.reset();
      this.formQuery.reset();
      this.formLocalisation.markAllAsTouched();
      this.formQuery.markAllAsTouched();
    }
  }

  /**
   * Replace null value by '' in json object to avoid send null in params
   */
  private removeNull(obj: any): any {
    Object.keys(obj).forEach((key) => { if (obj[key] === null) { obj[key] = ''; } });
    return obj;
  }
}
