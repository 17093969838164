<div id="navigation" class="navigation-container">
  <!-- Tabs -->
  <div class="ul-block">
    <ul
      id="tabs"
      role="tablist"
      aria-label="Formulaire de demande à renseigner"
    >
      <ng-container *ngFor="let tab of tabs; let index = index">
        <h4 *ngIf="isBo && index === 0" class="text-center">Formulaire</h4>

        <li role="presentation">
          <button
            id="tabpanel-{{ tab.etape }}"
            role="tab"
            [routerLink]="[{ outlets: { etape: [tab.etape] } }]"
            [routerLinkActive]="['is-active']"
            [hidden]="isEtapeHidden(tab.etape)"
            [matTooltip]="showTooltip(tab.etape)"
            [attr.aria-selected]="isCurrentTab(tab.etape)"
            [tabindex]="isCurrentTab(tab.etape) ? 0 : -1"
            aria-controls="tabpanel-{{ tab.etape }}-panel"
          >
            <mat-icon aria-hidden="true" mat-list-icon
              >{{ tab.icon }}
            </mat-icon>
            <span mat-line *ngIf="isCurrentTab(tab.etape); else inactive"
              ><strong>{{ tab.name }}</strong></span
            >
            <ng-template #inactive>
              <span mat-line>{{ tab.name }}</span>
            </ng-template>
            <lib-etape-indicateur
              [valid]="isEtapeValid(tab.etape)"
              [display]="isEtapeDisplayed(tab.display, tab.etape)"
              [isAdmin]="tab.isAdmin"
            ></lib-etape-indicateur>
          </button>
        </li>

        <mat-divider></mat-divider>

        <h4 *ngIf="isBo && index === 3" class="text-center mt-2">
          Administration
        </h4>
      </ng-container>
    </ul>
    <div
      id="actions"
      class="actions-container text-center mt-2 d-flex flex-column justify-content-center"
    >
      <ng-content></ng-content>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    appearance="outlined"
    id="tabpanel-{{ currentTabName }}-panel"
    role="tabpanel"
    tabindex="0"
    [attr.aria-labelledby]="'tabpanel-' + currentTabName"
  >
    <p class="hint-form">* les champs sont obligatoires</p>

    <router-outlet name="etape"></router-outlet>

    <div
      class="navigation d-flex w-100"
      [ngClass]="
        hasNext() && hasPrevious()
          ? 'justify-content-between'
          : hasNext()
          ? 'flex-row-reverse'
          : ''
      "
    >
      <button
        *ngIf="hasPrevious()"
        #previousBtn
        mat-button
        class="previous"
        (click)="previousStep()"
        aria-label="Étape précédente"
      >
        <mat-icon aria-hidden="true">arrow_back_ios</mat-icon>
        <span>Étape précédente</span>
      </button>
      <button
        *ngIf="hasNext() && !isNextEtapeHidden()"
        #nextBtn
        mat-button
        class="next"
        (click)="nextStep()"
        aria-label="Étape suivante"
      >
        <span>Étape suivante</span>
        <mat-icon aria-hidden="true">arrow_forward_ios</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
