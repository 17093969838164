import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map, startWith } from 'rxjs/operators';
import { CODE_HTTP } from '../../../constants/code-http.enum';
import { CODE_PAYS } from '../../../constants/referentiel/code-pays.enum';
import { Ape } from '../../../models/ape.model';
import { Employeur } from '../../../models/employeur.model';
import { Pays } from '../../../models/pays.model';
import { LibHttpDemandeService } from '../../../services/provided/lib-http-demande.service';
import { DemandeSharedService } from '../../../services/shared/demande-shared.service';

@Component({
  selector: 'lib-employeur',
  templateUrl: './employeur.component.html',
  styleUrls: ['./employeur.component.scss']
})
export class EmployeurComponent implements OnInit {
  public readonly CODE_PAYS = CODE_PAYS;

  public errorApiSirene = false;
  public callingSirene = false; // Quand appel WS SIRENE en cours
  public messageErrorApiSirene = '';

  public parentForm: UntypedFormGroup;
  public form: UntypedFormGroup;
  public listeAPE: Ape[];
  public listePays: Pays[];

  public listeFiltreeAPE: Observable<Ape[]>;

  constructor(
    private readonly demandeSharedService: DemandeSharedService,
    @Inject('LibHttpDemandeService')
    private readonly demandeSvc: LibHttpDemandeService,
  ) { }

  ngOnInit(): void {
    this.parentForm = this.demandeSharedService.form;
    this.form = this.parentForm.get('employeur') as UntypedFormGroup;
    this.listeAPE = this.demandeSharedService.resolvedData.apes;
    this.listePays = this.demandeSharedService.resolvedData.pays;

    // If it's a paper demand and siret is present
    // call the siren service to populate the datas
    if (this.isPapier && this.isPapier.value && this.employeurSiret.value && !this.form.touched) {
      this.callWebserviceSirene(this.employeurSiret.value);
    }

    this.listeFiltreeAPE = this.employeurApe.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (value) {
          if (value.code) {
            return this.listeAPE.filter(ape => ape.code === value.code);
          }
          const filterValue = value.toLowerCase();
          return this.listeAPE.filter(ape =>
            ape.code.includes(filterValue) || ape.intitule.includes(filterValue)
          );
        }
        return this.listeAPE;
      }),
    );
  }

  /// ACTIONS ///
  /**
   * Change option siret or ursaff
   */
  changeCaseSiret(): void {
    if (this.hasSiret) {
      this.employeurUrssaf.setValue(null);
      this.employeurSiret.enable();
      this.employeurUrssaf.disable();
    } else {
      this.employeurSiret.setValue(null);
      this.employeurSiret.disable();
      this.employeurUrssaf.enable();
    }
  }

  /**
   * Check or uncheck correspondant address
   */
  changeAdresseCorresp(event: MatCheckboxChange): void {
    if (event.checked) {
      this.adresseCorrespondance.enable();
    } else {
      this.adresseCorrespondance.disable();
    }
  }

  /**
   * Input siret change, call siren service
   */
  public siretChange(): void {
    this.employeurSiret.setValue(this.employeurSiret.value.replace(/\s/g, ''));
    if (this.employeurSiret.valid) {
      this.callingSirene = true;
      this.callWebserviceSirene(this.employeurSiret.value);
    }
  }

  public displayAPEValue(ape: Ape): string {
    if (ape && ape.code && ape.intitule) {
      return `${ape.code} - ${ape.intitule}`;
    }
    return '';
  }

  /**
   * Call siren service to get autocompleted information
   */
  private callWebserviceSirene(siret: string): void {
    this.errorApiSirene = false;

    this.demandeSvc.getWSSieneInfos(siret).pipe(
      finalize(() => this.callingSirene = false),
      catchError((error) => {
        this.errorApiSirene = true;

        this.messageErrorApiSirene = 'L\'API SIRENE est actuellement indisponible. ' +
          'Les informations de l\'employeur ne peuvent pas être récupérées automatiquement.';

        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case CODE_HTTP.CODE_ERREUR_MAITRISEE: // SIRET INCONNU
              this.messageErrorApiSirene = 'Le SIRET que vous avez saisi semble être inconnu de l\'API SIRENE. ' +
                'Les informations de l\'employeur ne peuvent pas être récupérées automatiquement.';
              break;
            case CODE_HTTP.CODE_ERREUR_VALIDATION:
              this.messageErrorApiSirene = 'Le SIRET que vous avez saisi fait référence à un établissement fermé. ' +
                'Les informations de l\'employeur ne peuvent pas être récupérées automatiquement.';
              break;
          }
        }

        return EMPTY;
      }),
    ).subscribe((employeur: Employeur) => {
      if (employeur) {
        this.errorApiSirene = false;
        this.messageErrorApiSirene = '';

        // Mise à jour du formulaire avec le retour du WS
        this.form.patchValue(employeur);
        this.form.markAllAsTouched();

        // update country, pays is null in the response for France
        this.adresse.patchValue({
          pays: this.listePays.find((pays) => (employeur.adresse.pays ? employeur.adresse.pays.code : CODE_PAYS.FRANCE) === pays.code),
        });
      }
    });
  }

  /**
   * compare equals function used for mat-selct
   */
  public compareFunction(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  ///// FORMULAIRE - GETTERS
  public get isPapier(): UntypedFormControl {
    return this.parentForm.get('isPapier') as UntypedFormControl;
  }
  public get id(): UntypedFormControl {
    return this.form.get('id') as UntypedFormControl;
  }

  public get employeurSiret(): UntypedFormControl {
    return this.form.get('siret') as UntypedFormControl;
  }

  public get employeurUrssaf(): UntypedFormControl {
    return this.form.get('urssaf') as UntypedFormControl;
  }

  public get employeurApe(): UntypedFormControl {
    return this.form.get('ape') as UntypedFormControl;
  }

  public get employeurRaisonSociale(): UntypedFormControl {
    return this.form.get('raisonSociale') as UntypedFormControl;
  }

  public get employeurNomSignataire(): UntypedFormControl {
    return this.form.get('nomSignataire') as UntypedFormControl;
  }

  public get employeurEffectif(): UntypedFormControl {
    return this.form.get('effectif') as UntypedFormControl;
  }

  public get employeurTelephone(): UntypedFormControl {
    return this.form.get('telephone') as UntypedFormControl;
  }

  public get employeurCourriel(): UntypedFormControl {
    return this.form.get('courriel') as UntypedFormControl;
  }

  public get adresse(): UntypedFormGroup {
    return this.form.get('adresse') as UntypedFormGroup;
  }

  public get adresseCorrespondance(): UntypedFormGroup {
    return this.form.get('adresseCorrespondance') as UntypedFormGroup;
  }

  public get hasSiret(): boolean {
    return this.form.get('hasSiret').value;
  }

  public get hasCorrespondance(): boolean {
    return this.form.get('hasCorrespondance').value;
  }
}
