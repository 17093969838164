import { OPERATION_COURRIER } from '../constants/referentiel/operation-courrier.enum';
import { TypeCourrier } from './type-courrier.model';

export class Courrier {
  id: number;
  date: Date;
  destinataires: string;
  identifiantExpediteur: string;
  typeCourrier: TypeCourrier;
  operation: OPERATION_COURRIER;
  type: string;
  courrielEmployeur: string;
  courrielSalarie: string;
  signatureId: number;
  lrar: boolean;

  public constructor({
    id = null,
    date = new Date(),
    destinataires = '',
    identifiantExpediteur = '',
    typeCourrier = null,
    operation = OPERATION_COURRIER.COURRIEL,
    type = '',
    courrielEmployeur = '',
    courrielSalarie = '',
    signatureId = null,
    lrar = false,
  }) {
    this.id = id;
    this.date = date;
    this.destinataires = destinataires;
    this.identifiantExpediteur = identifiantExpediteur;
    this.typeCourrier = typeCourrier;
    this.operation = operation;
    this.type = type;
    this.courrielEmployeur = courrielEmployeur;
    this.courrielSalarie = courrielSalarie;
    this.signatureId = signatureId;
    this.lrar = lrar;
  }
}
