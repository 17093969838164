export class JobName {
  public id: number;
  public jobName: string;
  constructor({
    id = null,
    jobName = '',

  }) {
    this.id = id;
    this.jobName = jobName;
  }
}
