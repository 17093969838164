<div>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>
          Faites votre simulation des délais de rétractation et d'homologation à
          respecter
        </h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p class="italic">
        La loi prévoit la possibilité, pour l'employeur comme pour le salarié,
        de se rétracter dans un délai de
        <span class="bold">15 jours calendaires (tous les jours comptent)</span
        >.
        <br />
        Ce délai commence à courir à compter du lendemain de la signature
        manuscrite du formulaire.
        <br />
        Lorsque le dernier jour du délai tombe un samedi, un dimanche ou un jour
        férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.
        Ce droit de rétractation est exercé sous la forme d'un écrit (lettre)
        adressé par tout moyen permettant d'attester de sa date de réception par
        l'autre partie.
        <br />
        Pour se prémunir de toute difficulté, la partie qui souhaite se
        rétracter a intérêt à le faire par lettre recommandée avec demande
        d'avis de réception ou par lettre remise à l'autre partie en main propre
        contre décharge, signée et mentionnant la date de remise.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="mt-1">
    <mat-card-content>
      <form [formGroup]="modeleForm">
        <p class="hint-form">* les champs sont obligatoires</p>
        <div class="row">
          <div class="col-4">
            <lib-date-form
              [form]="modeleForm"
              [isRequired]="true"
              dateControlName="dateSignature"
              label="Le formulaire est signé par les parties le..."
            >
            </lib-date-form>
          </div>
          <div class="col-6">
            <lib-autocomplete-commune
              [formLocalisation]="localisationForm"
            ></lib-autocomplete-commune>
          </div>
          <div class="col-2 pt-1">
            <button
              mat-button
              class="mat-primary"
              type="button"
              [disabled]="!dateSignatureCtrl.valid"
              (click)="calculateDates()"
            >
              Lancer la simulation
            </button>
          </div>
        </div>

        <div *ngIf="showResult">
          <div class="d-flex justify-content-between">
            <p>
              Les parties peuvent <span class="bold">se rétracter</span> pendant
              15 jours calendaires, soit jusqu'au :
            </p>
            <p class="date">
              {{ dateFinDelaiRetractationCtrl.value | date : "dd/MM/y" }}
            </p>
          </div>
          <mat-divider></mat-divider>

          <div class="d-flex justify-content-between mt-2">
            <p>
              Le formulaire peut être
              <span class="bold">adressé au service instructeur</span> dès le
              lendemain, soit à compter du :
            </p>
            <p class="date">
              {{ dateEnvoiFormulaireCtrl.value | date : "dd/MM/y" }}
            </p>
          </div>
          <mat-divider></mat-divider>

          <div class="d-flex justify-content-between mt-2">
            <p>
              Si vous envoyez le formulaire via le portail
              <span class="bold">(télétransmission)</span> le :
            </p>
            <p class="date">
              {{ dateEnvoiFormulaireCtrl.value | date : "dd/MM/y" }}
            </p>
          </div>
          <mat-divider></mat-divider>

          <div class="d-flex justify-content-between mt-2">
            <p>
              La <span class="bold">rupture du contrat</span> peut intervenir au
              plus tôt le lendemain de la fin du délai d'instruction de 15 jours
              ouvrables, soit au plus tôt le :
            </p>
            <p class="date">
              {{ dateFinDelaiInstructionCtrl.value | date : "dd/MM/y" }}
            </p>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
