import { Region } from './region.model';

export class DepartementOrgaMedDto {
  id: number;
  code: string;
  intitule: string;
  region: Region;

  public constructor({
    id = null,
    code = '',
    intitule = '',
    region = new Region({}),
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.region = region;
  }
}


