export class DecisionFile {
  id: number;
  uuid: string;
  fileName: string;
  fileSize: number;

  public constructor({
    id = null,
    uuid = '',
    fileName = '',
    fileSize = 0,
  }) {
    this.id = id;
    this.uuid = uuid;
    this.fileName = fileName;
    this.fileSize = fileSize;
  }
}
