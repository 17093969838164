import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TOAST_SOURCE } from '../../constants/toast-source.enum';
import { TYPE_TOAST } from '../../constants/type-toast.enum';

export class SircToast {
  public type: TYPE_TOAST;
  public title?: string;
  public body: any;
  public timeout?: number;

  public constructor(
    type,
    title = null as string,
    body,
    timeout = null as number,
  ) {
    this.type = type;
    this.title = title;
    this.body = body;
    this.timeout = timeout;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ToasterSharedService {
  public constructor(private injector: Injector,) {}

  

  public create(source: TOAST_SOURCE, data: SircToast): any {
    const toastService = this.injector.get<ToastrService>(ToastrService);

    switch(data.type){
      case TYPE_TOAST.SUCCES:{
        switch(source){
          case TOAST_SOURCE.INTRA:
            return toastService.success(`<p>${data.body}</p>`, data.title, {
              timeOut: data.timeout ?? 4000,
            });
          case TOAST_SOURCE.TELE:
            return toastService.success(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
          case TOAST_SOURCE.LIB:
            return toastService.success(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
        }
      }
      case TYPE_TOAST.INFORMATION:{
        switch(source){
          case TOAST_SOURCE.INTRA:
            return toastService.info(`<p>${data.body}</p>`, data.title, {
              timeOut: 0,
            });
          case TOAST_SOURCE.TELE:
            return toastService.info(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
          case TOAST_SOURCE.LIB:
            return toastService.info(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
        }
      }
      case TYPE_TOAST.AVERTISSEMENT:{
        switch(source){
          case TOAST_SOURCE.INTRA:
            return toastService.warning(`<p>${data.body}</p>`, data.title, {
              timeOut: 0,
            });
          case TOAST_SOURCE.TELE:
            return toastService.warning(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
          case TOAST_SOURCE.LIB:
            return toastService.warning(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
        }
      }
      case TYPE_TOAST.ERREUR:{
        switch(source){
          case TOAST_SOURCE.INTRA:
            return toastService.error(`<p>${data.body}</p>`, data.title, {
              timeOut: 0,
            });
          case TOAST_SOURCE.TELE:
            return toastService.error(`<p>${data.body}</p>`, data.title, {
              timeOut: 0,
            });
          case TOAST_SOURCE.LIB:
            return toastService.error(`<p>${data.body}</p>`, data.title, {
              timeOut: 6000,
            });
        }
      }
    }
  }

  public clear(toastId: number): void {
    const toastService = this.injector.get<ToastrService>(ToastrService);
    toastService.clear(toastId);
  }
}
