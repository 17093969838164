import { Adresse } from 'sirc-lib';
import { Localisation } from 'sirc-lib';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'formatAddress' })
export class AddressPipe implements PipeTransform {
  constructor(
  ) { }

  transform(address: Adresse): string {
    if (address && address.localisation) {
      return this.formatAddress(address) + this.formatCpVille(address.localisation);
    }
    return '';
  }

  private formatAddress(address: Adresse): string {
    return (address.complement ? `${address.complement}, ` : '')
      + (address.voie ? `${address.voie}, ` : '')
      + (address.bp ? `${address.bp}, ` : '');
  }

  private formatCpVille(localisation: Localisation): string {
    return (localisation.codePostal ? `${localisation.codePostal} ` : '') + (localisation.commune || '');
  }
}
