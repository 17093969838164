export class MotifDecision {
    id: number;
    actif: boolean;
    intitule: string;
    ordre: number;
    typeMotif: string;

    constructor() {
        this.id = undefined;
        this.actif = undefined;
        this.intitule = undefined;
        this.ordre = undefined;
        this.typeMotif = undefined;
    }
}
