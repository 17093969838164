export class DecisionFileConfig {
  maxSize: String;
  allowedFormats: String[];

  public constructor({
    maxSize = "",
    allowedFormats = [],
  }) {
    this.maxSize = maxSize;
    this.allowedFormats = allowedFormats;
  }
}
