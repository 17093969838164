export class QualiteAssistant {
  id: number;
  code: string;
  intitule: string;
  typeQa: string;
  constructor({
    id = null,
    code = '',
    intitule = '',
    typeQa = '',
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.typeQa = typeQa;
  }
}


