// Modules
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

// Components
import { AdresseComponent } from './components/adresse/adresse.component';
import { AutocompleteAdresseFrComponent } from './components/autocomplete-adresse-fr/autocomplete-adresse-fr.component';
import { AutocompleteCommuneComponent } from './components/autocomplete-commune/autocomplete-commune.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DateFormComponent } from './components/date-form/date-form.component';
import { CalendrierComponent } from './components/demande/calendrier/calendrier.component';
import { EmployeurComponent } from './components/demande/employeur/employeur.component';
import { EtapeIndicateurComponent } from './components/demande/etape-indicateur/etape-indicateur.component';
import { IndemniteComponent } from './components/demande/indemnite/indemnite.component';
import { NavigationComponent } from './components/demande/navigation/navigation.component';
import { SalarieComponent } from './components/demande/salarie/salarie.component';
import { MultipleArticlesComponent } from './components/espace-documentaire/multiple-articles/multiple-articles.component';
import { SingleArticleComponent } from './components/espace-documentaire/single-article/single-article.component';
import { MessageInfoComponent } from './components/message-info/message-info.component';
import { ConfirmModaleComponent } from './components/modales/confirm-modale.component';
import { SircIconComponent } from './components/sirc-icon/sirc-icon.component';
import { RgaaDisableDirective } from './directives/rgaa-disable.directive';
import { SircDatepickerFr } from './utils/sirc-datepicker-fr';

registerLocaleData(localeFr);

const MAT_MODULES = [
  MatDialogModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatListModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatSliderModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [
    EmployeurComponent,
    SalarieComponent,
    CalendrierComponent,
    IndemniteComponent,
    SircIconComponent,
    DateFormComponent,
    AdresseComponent,
    AutocompleteAdresseFrComponent,
    AutocompleteCommuneComponent,
    ConfirmModaleComponent,
    EtapeIndicateurComponent,
    BreadcrumbComponent,
    NavigationComponent,
    MultipleArticlesComponent,
    SingleArticleComponent,
    MessageInfoComponent,
    RgaaDisableDirective,
  ],
  imports: [
    ...MAT_MODULES,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...MAT_MODULES,
    AutocompleteCommuneComponent,
    CommonModule,
    ReactiveFormsModule,
    EmployeurComponent,
    SalarieComponent,
    CalendrierComponent,
    IndemniteComponent,
    DateFormComponent,
    ConfirmModaleComponent,
    BreadcrumbComponent,
    NavigationComponent,
    MultipleArticlesComponent,
    MessageInfoComponent,
    SircIconComponent,
    RgaaDisableDirective,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MatDatepickerIntl, useExisting: SircDatepickerFr },
  ]
})
export class LibModule { }
