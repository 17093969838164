export enum JOUR_CHOME_PAQUE {
    ASSOMPTION   = 'JC_ASSOM',
    PAQUES   = 'JC_PAQUES',
    LUNDI_PAQUES   = 'JC_LPAQUES',
    ASCENSION   = 'JC_ASCEN',
    PENTECOTE   = 'JC_PENTE',
    LUNDI_PENTECOTE   = 'JC_LPENTE',
    VENDREDI_SAINT   = 'JC_VSAIN',
}

export function getByCode(code: string): JOUR_CHOME_PAQUE {
  switch (code) {
    case 'JC_ASSOM':
      return JOUR_CHOME_PAQUE.ASSOMPTION;
    case 'JC_PAQUES':
      return JOUR_CHOME_PAQUE.PAQUES;
    case 'JC_LPAQUES':
      return JOUR_CHOME_PAQUE.LUNDI_PAQUES;
    case 'JC_ASCEN':
      return JOUR_CHOME_PAQUE.ASCENSION;
    case 'JC_PENTE':
      return JOUR_CHOME_PAQUE.PENTECOTE;
    case 'JC_LPENTE':
      return JOUR_CHOME_PAQUE.LUNDI_PENTECOTE;
    case 'JC_VSAIN':
      return JOUR_CHOME_PAQUE.VENDREDI_SAINT;
  }
}
