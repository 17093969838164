import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { InteractionErrorStateMatcher, SpinnerService, TOAST_SOURCE, TYPE_TOAST } from 'sirc-lib';
import { TemporaryDemandService } from '../../../core/services/temporary-demand.service';
import { DemandeService } from '../../../services/crud/demande.service';
import { SessionService } from '../../../shared/service/session.service';
import { TitleService, TitleState } from '../../../shared/service/title.service';


@Component({
  selector: 'app-demande-definition-password',
  templateUrl: './demande-definition-password.component.html',
  styleUrls: ['./demande-definition-password.component.scss']
})
export class DemandeDefinitionPasswordComponent implements OnInit {
  public matcher = new InteractionErrorStateMatcher();

  public form: UntypedFormGroup;

  public newDemand: boolean;

  public showPassword = false;
  public showConfirmPassword = false;

  public isSubmitting = false;

  private uuid: string;
  private token: string;

  constructor(
    private readonly titleService: TitleService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toasterService: ToasterSharedService,
    private readonly sessionService: SessionService,
    private readonly demandeService: DemandeService,
    private readonly temporaryDemandService: TemporaryDemandService,
    private readonly spinnerService: SpinnerService,
  ) { }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.params.uuid;
    this.token = this.route.snapshot.params.token;

    this.newDemand = !this.token;

    this.form = this.formBuilder.group({
      password: this.formBuilder.control('', {
        validators: [Validators.required, this.strongPassword],
      }),
      confirmPassword: this.formBuilder.control('', {
        validators: [Validators.required],
      }),
    },
      {
        validators: this.matchPassword,
      });

    this.titleService.changeTitle(TitleState.DEMANDE_PASSWORD_UPDATE);
  }

  public get passwordCtrl(): UntypedFormControl {
    return this.form.get('password') as UntypedFormControl;
  }

  public get confirmPasswordCtrl(): UntypedFormControl {
    return this.form.get('confirmPassword') as UntypedFormControl;
  }

  public submit(): void {
    this.spinnerService.showSpinner();
    this.isSubmitting = true;
    const obs$ = this.newDemand
      ? this.temporaryDemandService.passwordDefinition(this.uuid, this.passwordCtrl.value)
      : this.demandeService.passwordDefinition(this.uuid, this.token, this.passwordCtrl.value);

    obs$.subscribe({
      next: (loggedDemand) => {
        if (this.newDemand) {
          this.toasterService.create(
            TOAST_SOURCE.TELE,{
            type: TYPE_TOAST.SUCCES,
            title: 'Mot de passe créé',
            body: 'Votre mot de passe a été créé avec succès.',
          });
        } else {
          this.toasterService.create(
            TOAST_SOURCE.TELE,{
            type: TYPE_TOAST.SUCCES,
            title: 'Mot de passe modifié',
            body: 'Votre mot de passe a été modifié avec succès.',
          });
        }

        this.sessionService.token = loggedDemand.token;
        this.isSubmitting = false;
        this.router.navigate(['demandes/gestion', loggedDemand.uuid]).then(() => this.spinnerService.hideSpinner());
      },
      error: () => {
        this.titleService.changeTitle({ value: TitleState.SERVER_ERROR.value + TitleState.DEMANDE_PASSWORD_UPDATE.value });
        this.spinnerService.hideSpinner();
        this.toasterService.create(
          TOAST_SOURCE.TELE,{
          type: TYPE_TOAST.ERREUR,
          body: 'Une erreur est survenue, veuillez recréer le compte.',
        });
      }
    });
  }

  /**
   * Checks if given password complies with following rules:
   * - At least 8 characters
   * - At least one number
   * - At least one upper case character
   * - At least one lower case character
   * - At least one special character
   *
   * @param control given control including password value
   */
  private strongPassword(control: AbstractControl): ValidationErrors | null {
    const password: string = control.value;

    if (!password || (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\/\\!@#$%£€*§+=-]).{8,}$/.test(password))) {
      return null;
    }

    return { strong: true };
  }

  /**
   * Check if password and confirmation are the same
   *
   * @param control given form
   */
  private matchPassword(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          noPasswordMatch: true,
        });
        return { valid: false };
      }

      // [FIX] set errors = null only if values are defined
      // so it does not interfere with 'field required' error
      control.get('confirmPassword').setErrors(null);
    }

    return null;
  }
}
