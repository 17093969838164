import { UntypedFormGroup } from '@angular/forms';
import { DemandeEtape } from '../constants/demande-etape.enum';
import { Demande } from '../models/demande.model';
import { Entretien } from '../models/entretien.model';

export class MapperUtils {

  public static mapDemandeBo(form: UntypedFormGroup): Demande {
    // flat form controls
    const data = {
      ...form.getRawValue(),
      ... (form.get(DemandeEtape.GESTION) as UntypedFormGroup).getRawValue(),
      ... (form.get(DemandeEtape.DECISION) as UntypedFormGroup).getRawValue()
    };
    return this.mapRawValues(data);
  }

  public static mapDemande(form: UntypedFormGroup): Demande {
    const formValues = form.getRawValue();
    return this.mapRawValues(formValues);
  }

  private static mapRawValues(formValues: any): Demande {
    const demandeToSave = new Demande(formValues);
    // Calender form builder is not the same structure, need to map manuelly
    this._mapRemuneration(formValues[DemandeEtape.INDEMNITE], formValues.remuneration.salaire.prime, demandeToSave);
    this._mapCalendrier(formValues[DemandeEtape.CALENDRIER], demandeToSave);
    return demandeToSave;
  }

  /**
   * Map mauelly calender values to demande
   */
  private static _mapCalendrier(calendrierValues, demande: Demande): void {
    demande.entretiens = calendrierValues.entretiens.map((entretien) => new Entretien(entretien));
    demande.remarqueEntretiens = calendrierValues.remarqueEntretiens;
    demande.dateSignature = calendrierValues.dateSignature;
    demande.dateFinDelaiRetractation = calendrierValues.dateFinDelaiRetractation;
    demande.dateFinDelaiInstruction = calendrierValues.dateFinDelaiInstruction;
    demande.dateEnvisageeRupture = calendrierValues.dateEnvisageeRupture;
    demande.autresClauses = calendrierValues.autresClauses;
  }

  /**
   * Map mauelly remuneration values to demande
   */
  private static _mapRemuneration(remunerationValues, prime: number, demande: Demande): void {
    const remuneration = demande.remuneration;
    remuneration.indemnite = remunerationValues.results.indemnite;
    remuneration.anciennete = Number(remunerationValues.anciennete.ancienneteAnnee * 12) + Number(remunerationValues.anciennete.ancienneteMois);
    remuneration.moisDernierMois = remunerationValues.anciennete.moisDernierMois;
    remuneration.anneeDernierMois = remunerationValues.anciennete.anneeDernierMois;
    remuneration.mois1Valeur = remunerationValues.salaire.valeurs[0];
    remuneration.mois2Valeur = remunerationValues.salaire.valeurs[1];
    remuneration.mois3Valeur = remunerationValues.salaire.valeurs[2];
    remuneration.mois4Valeur = remunerationValues.salaire.valeurs[3];
    remuneration.mois5Valeur = remunerationValues.salaire.valeurs[4];
    remuneration.mois6Valeur = remunerationValues.salaire.valeurs[5];
    remuneration.mois7Valeur = remunerationValues.salaire.valeurs[6];
    remuneration.mois8Valeur = remunerationValues.salaire.valeurs[7];
    remuneration.mois9Valeur = remunerationValues.salaire.valeurs[8];
    remuneration.mois10Valeur = remunerationValues.salaire.valeurs[9];
    remuneration.mois11Valeur = remunerationValues.salaire.valeurs[10];
    remuneration.mois12Valeur = remunerationValues.salaire.valeurs[11];
    remuneration.moyenneRemunerationMensuelle = remunerationValues.results.moyenneRemunerationMensuelle;
    remuneration.prime = prime;
  }

}
