import { Departement } from './departement.model';
export class Region {
  id: number;
  code: string;
  intitule: string;
  departements: Departement[];

  public constructor({
    id = null,
    code = '',
    intitule = '',
    departements = [],
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.departements = departements;
  }
}
