import { AdresseFormBuilder } from './adresse-form.builder';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Salarie } from '../../models/salarie.model';

export class SalarieFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private salarie: Salarie,
    private isDisabled: boolean,
  ) { }

  build(): UntypedFormGroup {
    return this.fb.group({
      id: this.fb.control({
        value: this.salarie.id,
        disabled: true,
      }),

      civilite: this.fb.control({
        value: this.salarie.civilite,
        disabled: this.isDisabled,
      }),

      nom: this.fb.control({
        value: this.salarie.nom,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250)]
      ),

      prenom: this.fb.control({
        value: this.salarie.prenom,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250)]
      ),

      dateNaissance: this.fb.control({
        value: this.salarie.dateNaissance,
        disabled: this.isDisabled,
      },
        [Validators.required]
      ),

      adresse: new AdresseFormBuilder(this.fb, this.salarie.adresse, this.isDisabled).build(),

      telephone: this.fb.control({
        value: this.salarie.telephone,
        disabled: this.isDisabled,
      },
        [Validators.maxLength(20)]
      ),

      courriel: this.fb.control({
        value: this.salarie.courriel,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250), Validators.email]
      ),

      qualification: this.fb.control({
        value: this.salarie.qualification,
        disabled: this.isDisabled,
      },
        [Validators.required]
      ),

      emploi: this.fb.control({
        value: this.salarie.emploi,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250)]
      ),

      conventionCollective: this.fb.control({
        value: this.salarie.conventionCollective,
        disabled: this.isDisabled,
      },
        [Validators.required, validateConventionCollective]
      ),
    });
  }
}

export const validateConventionCollective: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return !control.value?.codeIdcc ? { emptyCodeIdcc: true } : null;
};
