import { MESSAGE_IMPORTANCE } from '../constants/referentiel/message-importance.enum';

export class MessageInfo {
  id: number;
  code: string;
  titre: string;
  datePublication: Date;
  contenu: string;
  visible: boolean;
  importance: MESSAGE_IMPORTANCE;

  public constructor({
    id = null,
    code = '',
    titre = '',
    datePublication = null,
    contenu = '',
    visible = true,
    importance = MESSAGE_IMPORTANCE.INFO,
  }) {
    this.id = id;
    this.code = code;
    this.titre = titre;
    this.datePublication = datePublication;
    this.contenu = contenu;
    this.visible = visible;
    this.importance = importance;
  }
}
