import { TYPE_COURRIER } from '../constants/type-courrier.enum';

export class TypeCourrier {
  id: number;
  code: TYPE_COURRIER;
  intitule: string;

  constructor({
    id = 0,
    code = null,
    intitule = '',
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
  }
}


