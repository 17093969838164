<form [formGroup]="form">
  <h3>Employeur</h3>
  <section id="identifiant-etablissement">
    <h4>Identité de l'établissement</h4>

    <div class="container-fluid">
      <div>
        <label>Type d'identifiant de l'établissement :</label>
      </div>

      <div class="radio-container">
        <mat-radio-group
          aria-labelledby="label-identifiant"
          formControlName="hasSiret"
          (change)="changeCaseSiret()"
        >
          <mat-radio-button class="me-2" [value]="true"
            >SIRET (14 chiffres)</mat-radio-button
          >
          <mat-radio-button [value]="false"
            >Je ne dispose pas de SIRET</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <!-- Siret -->
      <div *ngIf="hasSiret; else ursaff">
        <mat-form-field class="identifiant" appearance="outline">
          <mat-label>Identifiant de l'établissement</mat-label>

          <input
            type="text"
            matInput
            formControlName="siret"
            placeholder="SIRET (14 chiffres)"
            (change)="siretChange()"
            required
          />

          <mat-progress-bar
            mode="indeterminate"
            *ngIf="callingSirene"
          ></mat-progress-bar>

          <lib-sirc-icon
            matSuffix
            [form]="employeurSiret"
            [tooltipText]="
              'Il s\'agit du SIRET de l\'établissement où est employé le salarié et non celui du siège de l\'entreprise ou d\'un autre établissement gérant les dossiers.'
            "
          >
          </lib-sirc-icon>

          <mat-error *ngIf="employeurSiret.hasError('required')">
            <p>
              Veuillez renseigner ce champ ou cocher la case "Je ne dispose pas
              de SIRET"
            </p>
          </mat-error>
          <mat-error
            *ngIf="
              employeurSiret.hasError('maxlength') ||
                employeurSiret.hasError('minlength') ||
                employeurSiret.hasError('pattern');
              else ifLuhnAlgorithmError
            "
          >
            <p>
              Le siret renseigné doit être composé de 14 chiffres : les 9
              chiffres du numéro de SIREN + les 5 chiffres du NIC
            </p>
          </mat-error>
          <ng-template #ifLuhnAlgorithmError>
            <ng-container *ngIf="employeurSiret.hasError('isNumeroSiret')">
              <mat-error>
                <p>Le siret renseigné ne respecte pas la clé Luhn</p>
              </mat-error>
            </ng-container>
          </ng-template>
        </mat-form-field>

        <mat-error
          *ngIf="messageErrorApiSirene"
          role="alert"
          class="display-warn"
        >
          <mat-icon aria-hidden="true" [color]="'warn'">error_outline</mat-icon>
          <p>{{ messageErrorApiSirene }}</p>
        </mat-error>
      </div>

      <ng-template #ursaff>
        <!-- ursaff-->
        <mat-form-field class="identifiant" appearance="outline">
          <mat-label>Numéro de cotisant</mat-label>

          <input
            type="text"
            matInput
            formControlName="urssaf"
            placeholder="ex : numéro URSSAF"
            required
          />

          <lib-sirc-icon
            matSuffix
            [form]="employeurUrssaf"
            [tooltipText]="
              'Les employeurs ne disposant pas de numéro SIRET (particulier-employeur, entreprise située à l\'étranger….) doivent indiquer, par défaut, leur numéro de cotisant : n° URSSAF, CESU, PAJemploi…'
            "
          >
          </lib-sirc-icon>

          <mat-error *ngIf="employeurUrssaf.hasError('required')">
            <p>Ce champ est obligatoire</p>
          </mat-error>
          <mat-error *ngIf="employeurUrssaf.hasError('maxlength')">
            <p>Taille maximale : 250 caractères</p>
          </mat-error>
        </mat-form-field>
      </ng-template>

      <!-- Name-->
      <mat-form-field appearance="outline">
        <mat-label>Nom ou raison sociale</mat-label>

        <input type="text" matInput formControlName="raisonSociale" required />

        <lib-sirc-icon
          matSuffix
          [form]="employeurRaisonSociale"
          [tooltipText]="
            'Si l\'employeur est un particulier, indiquer son nom précédé de la civilité.'
          "
        >
        </lib-sirc-icon>

        <mat-error *ngIf="employeurRaisonSociale.hasError('required')">
          <p>Ce champ est obligatoire</p>
        </mat-error>
        <mat-error *ngIf="employeurRaisonSociale.hasError('maxlength')">
          <p>Taille maximale : 250 caractères</p>
        </mat-error>
      </mat-form-field>

      <div class="row">
        <div class="col-10">
          <mat-form-field appearance="outline">
            <mat-label>APE</mat-label>
            <input
              matInput
              type="text"
              placeholder=""
              formControlName="ape"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayAPEValue"
            >
              <mat-option
                *ngFor="let ape of listeFiltreeAPE | async"
                [value]="ape"
              >
                {{ ape.code }} - {{ ape.intitule }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="outline">
            <mat-label>Effectif</mat-label>

            <input type="number" matInput formControlName="effectif" />

            <mat-error *ngIf="employeurEffectif.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="employeurEffectif.hasError('pattern')">
              <p>Numérique uniquement</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <!--Signature-->
          <mat-form-field appearance="outline">
            <mat-label
              >Nom du signataire pour le compte de l'employeur</mat-label
            >

            <input type="text" matInput formControlName="nomSignataire" />

            <lib-sirc-icon
              matSuffix
              [form]="employeurNomSignataire"
              [tooltipText]="
                'Nom de la personne signataire de la convention de rupture et habilitée par l\'établissement pour le faire. Elle sera par défaut le correspondant du service instructeur.'
              "
            >
            </lib-sirc-icon>
            <mat-error *ngIf="employeurNomSignataire.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Courriel-->
          <mat-form-field appearance="outline">
            <mat-label>Courriel</mat-label>

            <input
              type="email"
              matInput
              formControlName="courriel"
              placeholder="Ex. courriel@domaine.com"
              required
            />

            <mat-error *ngIf="employeurCourriel.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="employeurCourriel.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
            <mat-error *ngIf="employeurCourriel.hasError('email')">
              <p>
                L'adresse courriel renseignée n'est pas valide
                (adresse&#64;domaine.fr)
              </p>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <!--Téléphone-->
          <mat-form-field appearance="outline">
            <mat-label>Téléphone</mat-label>

            <input
              type="tel"
              matInput
              formControlName="telephone"
              placeholder="Ex. 060000000000"
            />

            <mat-error *ngIf="employeurTelephone.hasError('maxlength')">
              <p>Taille maximale : 20 caractères</p>
            </mat-error>
            <mat-error *ngIf="employeurTelephone.hasError('pattern')">
              <p>
                Le téléphone renseigné doit être uniquement composé de chiffres
              </p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </section>

  <section id="adresse-etablissement">
    <fieldset>
      <legend>
        <h4>Adresse de l'établissement</h4>
        <lib-sirc-icon
          [form]="adresse"
          [tooltipText]="
            'Nom de la personne signataire de la convention de rupture et habilitée par l\'établissement pour le faire. Elle sera par défaut le correspondant du service instructeur.'
          "
        >
        </lib-sirc-icon>
      </legend>
    </fieldset>

    <lib-adresse [adresseForm]="adresse"></lib-adresse>
  </section>

  <section id="adresse-correspondance">
    <div class="d-flex align-items-center">
      <mat-checkbox
        class="corresp"
        formControlName="hasCorrespondance"
        (change)="changeAdresseCorresp($event)"
      >
        Adresse différente pour les correspondances
      </mat-checkbox>

      <lib-sirc-icon
        [form]="adresseCorrespondance"
        [tooltipText]="
          'Si les courriers doivent être adressés à un autre établissement que celui où est employé le salarié, cette option vous permet de remplir correctement une autre adresse.'
        "
      >
      </lib-sirc-icon>
    </div>

    <fieldset *ngIf="hasCorrespondance" class="mt-1">
      <legend>
        <h4>Adresse des correspondances</h4>
      </legend>
      <lib-adresse [adresseForm]="adresseCorrespondance"></lib-adresse>
    </fieldset>
  </section>
</form>
