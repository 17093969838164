import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from '../../../models/article.model';
import { AbstractBreadcrumbService, BreadcrumbState } from '../../../services/provided/abstract-breadcrumb.service';

@Component({
  selector: 'lib-single-article',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.scss']
})
export class SingleArticleComponent {

  public title: string;
  public article: Article;

  constructor(
    private route: ActivatedRoute,
    @Inject('BreadcrumbSharedService') private breadcrumbSharedService: AbstractBreadcrumbService,
  ) {
    this.route.data.subscribe(data => {
      this.breadcrumbSharedService.setState(BreadcrumbState.HELP_LIBRARY, data.title);
      this.title = data.title;
      this.article = data.articles[0];
    });
  }

}
