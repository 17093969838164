import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Remuneration } from '../../models/remuneration.model';

export class SalaireFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private remuneration: Remuneration,
    private isDisabled: boolean,
  ) { }

  build(): UntypedFormGroup {

    return this.fb.group({

      isIdentique: this.fb.control(
        {
          value: this.remuneration.mois1Valeur === this.remuneration.mois2Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois3Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois4Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois5Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois6Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois7Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois8Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois9Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois10Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois11Valeur
            && this.remuneration.mois1Valeur === this.remuneration.mois12Valeur,
          disabled: this.isDisabled,
        }
      ),

      valeurs: this.fb.array(
        [
          this.remuneration.mois1Valeur,
          this.remuneration.mois2Valeur,
          this.remuneration.mois3Valeur,
          this.remuneration.mois4Valeur,
          this.remuneration.mois5Valeur,
          this.remuneration.mois6Valeur,
          this.remuneration.mois7Valeur,
          this.remuneration.mois8Valeur,
          this.remuneration.mois9Valeur,
          this.remuneration.mois10Valeur,
          this.remuneration.mois11Valeur,
          this.remuneration.mois12Valeur
        ]
          .map(value => this.createRenumerationParMois(value))
      ),

      prime: this.fb.control({
        value: this.remuneration.prime,
        disabled: this.isDisabled
      },
        [Validators.min(0), Validators.pattern(/^[0-9]+[.,]{0,1}[0-9]{0,2}$/)]
      ),
    });
  }

  createRenumerationParMois(valeur: number): UntypedFormControl {
    return this.fb.control({
      value: valeur,
      disabled: this.isDisabled
    },
      [Validators.required, Validators.min(0), Validators.pattern(/^[0-9]+[.,]{0,1}[0-9]{0,2}$/)]
    );
  }
}
