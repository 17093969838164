<div *ngIf="apiAdresseAvailable; else apiAdresseisDown">
  <div [formGroup]="formQuery">
    <div>
      <p style="display: inline;">Vous ne trouvez pas votre Adresse ? </p>
      <button *ngIf="!isManualAdress;"
              mat-button class="mat-focus-indicator menu-button btn mat-button mat-button-base mb-025"
              data-toggle="tooltip" data-placement="bottom"
              (click)="changeAdresseMode()"
      >Saisie manuelle</button>
      <button *ngIf="isManualAdress;"
              mat-button class="mat-focus-indicator menu-button btn mat-button mat-button-base"
              data-toggle="tooltip" data-placement="bottom"
              (click)="changeAdresseMode()"
      >Assistance à la saisie</button>
    </div>

    <mat-form-field *ngIf="!isManualAdress" class="full-width" appearance="outline">
      <mat-label>Saisissez l'adresse</mat-label>
      <input matInput type="text" formControlName="query" name="query" [matAutocomplete]="auto"
        (blur)="validateAdresse()" aria-label="Adresse complète" required>
      <mat-progress-bar *ngIf="loadingAutocomplete" class="progress" mode="query"></mat-progress-bar>
      <mat-error *ngIf="formControlQuery.hasError('required')">
        <p>Ce champ est obligatoire</p>
      </mat-error>

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAdresse" (optionSelected)="selectAdresse($event)"
        (closed)="validateAdresse()">
        <div *ngIf="responseAdresse | async as listAdresse">
          <ng-container *ngIf="listAdresse.length > 0; else noResult">
            <mat-option *ngFor="let adresse of listAdresse" class="option-adresse" [value]="adresse">
              <span>{{ adresse.voie }}, <span class="bold">{{ adresse.localisation.codePostal }} - {{
                  adresse.localisation.commune }}</span></span>
            </mat-option>
          </ng-container>

          <ng-template #noResult>
            <mat-option disabled>Aucun résultat</mat-option>
          </ng-template>
        </div>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div *ngIf="isManualAdress" [formGroup]="formAdresse">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Numéro, voie</mat-label>
      <input matInput type="text" formControlName="voie" name="voie" aria-label="Numéro et voie de l'adresse" required />
    </mat-form-field>
    <mat-error *ngIf="adresseVoie.hasError('required')">
      <p>Ce champ est obligatoire</p>
    </mat-error>
    <lib-autocomplete-commune [formLocalisation]="adresseLocalisation"></lib-autocomplete-commune>
  </div>

</div>

<ng-template #apiAdresseisDown >
  <!-- concrete adresse (not visible)-->
  <mat-error role="alert" class="display-warn">
    <mat-icon aria-hidden="true" [color]="'warn'">error_outline</mat-icon>
    <p>Service indisponible, veuillez remplir les informations à la main</p>
  </mat-error>
  <div [formGroup]="formAdresse">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Numéro, voie</mat-label>
      <input matInput type="text" formControlName="voie" name="voie" aria-label="Numéro et voie de l'adresse" required />
    </mat-form-field>
    <mat-error *ngIf="adresseVoie.hasError('required')">
      <p>Ce champ est obligatoire</p>
    </mat-error>
    <lib-autocomplete-commune [formLocalisation]="adresseLocalisation"></lib-autocomplete-commune>

  </div>
</ng-template>
