// Modules
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ToastrModule, ToastContainerDirective } from 'ngx-toastr';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BooleanPipe } from '../app/pipes/boolean-pipe.pipe';
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
// Guards + Handler + Directives + Pipes
import { SircErrorsHandler } from './core/handler/sirc-errors-handler';
import { SircLoaderDirective } from './directives/sirc-loader.directive';
import { GenericPipe } from './pipes/generic-pipe.pipe';
import { NumberToMonthPipe } from './pipes/number-to-month.pipe';
import { BreadcrumbSharedService } from './services/breadcrumb.service';
// Services
import { ConfigurationService } from './services/configuration.service';
import { ArticleService } from './services/crud/article.service';
import { DemandeService } from './services/crud/demande.service';
import { TitleService } from './shared/service/title.service';
import { SharedModule } from './shared/shared.module';
import { AvoidanceLinkComponent } from './web/commun/avoidance-link/avoidance-link.component';
import { DialogCreateDemandComponent } from './web/commun/dialog-create-demand/dialog-create-demand.component';
import { DialogEditDemandComponent } from './web/commun/dialog-edit-demand/dialog-edit-demand.component';
import { FooterComponent } from './web/commun/global-view/footer/footer.component';
import { HeaderComponent } from './web/commun/global-view/header/header.component';
import { LoaderComponent } from './web/commun/loader/loader.component';
import { DemandeDefinitionPasswordComponent } from './web/demandes/demande-definition-password/demande-definition-password.component';
import { HomeComponent } from './web/home/home.component';
import { SimulateurDelaisComponent } from './web/simulateur-delais/simulateur-delais.component';
import { SimulateurIndemniteComponent } from './web/simulateur-indemnite/simulateur-indemnite.component';
import { SuiviHomologationComponent } from './web/suivi-homologation/suivi-homologation.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SuiviHomologationComponent,
    SimulateurDelaisComponent,
    SimulateurIndemniteComponent,
    LoaderComponent,
    DialogCreateDemandComponent,
    DialogEditDemandComponent,
    SircLoaderDirective,
    GenericPipe,
    NumberToMonthPipe,
    BooleanPipe,
    DemandeDefinitionPasswordComponent,
    AvoidanceLinkComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NgxCaptchaModule,
    ToastrModule.forRoot({
      closeButton: true,
      easing: 'ease-out',
      enableHtml: true,
      newestOnTop: true,
      tapToDismiss: true,
    }),
    ToastContainerDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: APP_INITIALIZER, useFactory: initializer, multi: true, deps: [ConfigurationService] },
    { provide: ErrorHandler, useClass: SircErrorsHandler },
    { provide: 'LibHttpDemandeService', useExisting: DemandeService },
    { provide: 'LibConfigurationService', useExisting: ConfigurationService },
    { provide: 'LibArticleService', useExisting: ArticleService },
    { provide: 'BreadcrumbSharedService', useExisting: BreadcrumbSharedService },
    { provide: 'TitleService', useExisting: TitleService },
    DatePipe,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// Initialise la configuration externalisée de l'application
export function initializer(config: ConfigurationService): () => Promise<any> {
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try { // Configuration de l'APP
        await config.load();
        resolve();
      } catch (error) {
        console.error(`ERREUR - une erreur critique est survenue lors du chargement de la configuration de l'application.`);
        reject(error);
      }
    });
  };
}
