export enum ArticleTypeEnum {
  // TeleRC
  GUIDE = 'GUIDE',
  FAQ = 'FAQ',
  RUPTURE = 'RUPTURE',
  ACCESSIBILITE = 'ACCESSIBILITE',
  CONTACTS = 'CONTACTS',
  DONNES_PERSONNELLES = 'DONNES_PERSONNELLES',
  MENTIONS_LEGALES = 'MENTIONS_LEGALES',
  // IntraRC
  FAQ_LEGAL = 'FAQ_LEGAL',
  FAQ_FUNCTIONAL = 'FAQ_FUNCTIONAL',
  DOCUMENTS = 'DOCUMENTS',
  CONTACT = 'CONTACT',
  GUIDE_INTRA = 'GUIDE_INTRA',
}
