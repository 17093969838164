import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'lib-sirc-icon',
    templateUrl: './sirc-icon.component.html'
})
export class SircIconComponent {

    @Input() tooltipText = '';

    @Input() form: UntypedFormControl;

    constructor() { }

    /**
     * RGAA : icon not focusable by keyboad if the related form is disabled
     *
     */
    public get notFocusable(): boolean {
      return this.form && this.form.disabled;
    }

}
