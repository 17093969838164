import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { BreadcrumbState, CalculatedDates } from 'sirc-lib';
import { BreadcrumbSharedService } from '../../services/breadcrumb.service';
import { DemandeService } from '../../services/crud/demande.service';
import { JourChomeService } from '../../services/crud/jour-chome.service';
import { TitleService, TitleState } from '../../shared/service/title.service';

@Component({
  selector: 'app-simulateur-delais',
  templateUrl: './simulateur-delais.component.html',
  styleUrls: ['./simulateur-delais.component.scss']
})
export class SimulateurDelaisComponent implements OnInit {

  public showResult = false;

  public modeleForm = this.formBuilder.group({
    dateSignature: this.formBuilder.control(null, Validators.required),
    localisation: this.formBuilder.control(null, Validators.required),
    dateFinDelaiRetractation: this.formBuilder.control(null),
    dateEnvoiFormulaire: this.formBuilder.control(null),
    dateFinDelaiInstruction: this.formBuilder.control(null),
  });

  constructor(
    private readonly titleService: TitleService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly jourChomeService: JourChomeService,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: BreadcrumbSharedService,
    private readonly demandeService: DemandeService,
    private datePipe: DatePipe,
  ) { }

  public ngOnInit(): void {
    this.breadcrumbSharedService.setState(BreadcrumbState.SIMULATOR_DEADLINES);

    this.titleService.changeTitle(TitleState.SIMULATOR_DEADLINES);
  }

  calculateDates(): void {
    if (this.dateSignatureCtrl.valid && this.localisationCtrl.valid) {
      this.demandeService.calculateDatesForFo(
        this.datePipe.transform(this.dateSignatureCtrl.value, 'dd/MM/yyyy'),
        this.localisationCtrl.value.codePostal,
        this.localisationCtrl.value.numeroInsee,
      ).pipe(
        catchError(() => {
          this.showResult = false;
          return of(null);
        }),
        filter((res) => res && res !== null),
      ).subscribe((dates: CalculatedDates) => {
        this.dateFinDelaiRetractationCtrl.setValue(dates.dateFinDelaiRetractation);
        this.dateEnvoiFormulaireCtrl.setValue(moment(dates.dateFinDelaiRetractation).add(1, 'days').toDate());
        this.dateFinDelaiInstructionCtrl.setValue(moment(dates.dateFinDelaiInstruction).add(1, 'days').toDate());
        this.showResult = true;
      });
    } else {
      this.showResult = false;
    }
  }

  public get dateSignatureCtrl(): UntypedFormControl {
    return this.modeleForm.get('dateSignature') as UntypedFormControl;
  }
  public get dateFinDelaiRetractationCtrl(): UntypedFormControl {
    return this.modeleForm.get('dateFinDelaiRetractation') as UntypedFormControl;
  }
  public get dateEnvoiFormulaireCtrl(): UntypedFormControl {
    return this.modeleForm.get('dateEnvoiFormulaire') as UntypedFormControl;
  }
  public get dateFinDelaiInstructionCtrl(): UntypedFormControl {
    return this.modeleForm.get('dateFinDelaiInstruction') as UntypedFormControl;
  }
  public get localisationForm(): UntypedFormGroup {
    return this.modeleForm.get('localisation') as UntypedFormGroup;
  }
  public get localisationCtrl(): UntypedFormControl {
    return this.modeleForm.get('localisation') as UntypedFormControl;
  }
}
