<form [formGroup]="form">
  <h3>Salarié(e)</h3>
  <!--Identité du ou de la salarié(e)-->
  <section id="identifiant-salarie" role="group">
    <h4>Identité du ou de la salarié(e)</h4>
    <div class="container-fluid">
      <fieldset class="field-civilite">
        <legend id="civilite-label" class="float-start me-2">Civilité :</legend>

        <mat-radio-group
          aria-labelledby="civilite-label"
          formControlName="civilite"
        >
          <mat-radio-button class="me-1" [value]="CIVILITE.MADAME">{{
            CIVILITE.MADAME
          }}</mat-radio-button>
          <mat-radio-button [value]="CIVILITE.MONSIEUR">{{
            CIVILITE.MONSIEUR
          }}</mat-radio-button>
        </mat-radio-group>
      </fieldset>

      <div class="row mt-1">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Nom</mat-label>

            <input type="text" matInput formControlName="nom" required />

            <mat-error *ngIf="salarieNom.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="salarieNom.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Prénom</mat-label>

            <input type="text" matInput formControlName="prenom" required />

            <mat-error *ngIf="salariePrenom.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="salariePrenom.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col">
          <lib-date-form
            [form]="form"
            dateControlName="dateNaissance"
            [datePickerFilter]="dateNaissFilter"
            [maxDate]="TODAY"
            [isRequired]="true"
            label="Date de naissance"
          >
          </lib-date-form>
        </div>
      </div>
    </div>
  </section>

  <!--Adresse du ou de la salarié(e)-->
  <section id="adresse-salarie" role="group">
    <h4>Adresse du ou de la salarié(e)</h4>
    <lib-adresse [adresseForm]="adresse"></lib-adresse>
  </section>

  <!--Coordonnées du ou de la salarié(e)-->
  <section role="group">
    <h4>Coordonnées du ou de la salarié(e)</h4>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Courriel</mat-label>

            <input
              type="email"
              matInput
              formControlName="courriel"
              placeholder="Ex. courriel@domaine.com"
              required
            />

            <mat-error *ngIf="salarieCourriel.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="salarieCourriel.hasError('email')">
              <p>
                Veuillez saisir une adresse courriel au format
                adresse&#64;domaine.fr
              </p>
            </mat-error>
            <mat-error *ngIf="salarieCourriel.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Téléphone</mat-label>

            <input
              type="tel"
              matInput
              formControlName="telephone"
              placeholder="Ex. 060000000000"
            />

            <mat-error *ngIf="salarieTelephone.hasError('maxlength')">
              <p>Taille maximale : 20 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </section>

  <!--Qualification-->
  <section id="qualification" role="group">
    <div class="d-flex align-items-center">
      <h4 class="d-inline-block">Qualification</h4>

      <lib-sirc-icon
        [form]="salarieQualification"
        [tooltipText]="
          'Sélectionner la qualification du salarié. Cette information est nécessaire pour vérifier le montant de l\'indemnité qui peut varier en fonction de la catégorie d’emploi (ouvrier, agent de maîtrise, employé, cadre….) dans la convention collective.'
        "
      >
      </lib-sirc-icon>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <fieldset>
            <legend id="label-qualification">
              Sélectionner une qualification * :
            </legend>

            <mat-radio-group
              aria-labelledby="label-qualification"
              class="qualification-radio-group"
              formControlName="qualification"
            >
              <mat-radio-button
                *ngFor="let qualification of listQualification"
                class="qualification-radio-button"
                [value]="qualification"
              >
                {{ qualification.intitule }}
              </mat-radio-button>
            </mat-radio-group>
          </fieldset>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Emploi</mat-label>

            <input type="text" matInput formControlName="emploi" required />

            <lib-sirc-icon
              matSuffix
              [form]="salarieEmploi"
              [tooltipText]="
                'Indiquer l’emploi occupé par le salarié au moment de la rupture. Ex. : vendeur, artisan maçon…'
              "
            >
            </lib-sirc-icon>

            <mat-error *ngIf="salarieEmploi.hasError('required')">
              <p>Ce champ est obligatoire</p>
            </mat-error>
            <mat-error *ngIf="salarieEmploi.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </section>

  <!--Convention collective-->
  <section role="group">
    <div class="d-flex align-items-center">
      <h4 class="d-inline-block">Convention collective</h4>

      <lib-sirc-icon
        [form]="salarieConventionCollective"
        [tooltipText]="
          'Indiquer la convention collective applicable au salarié. Cette information figure normalement dans le contrat de travail ou sur le bulletin de paie. Le module vous permet de retrouver le nom à partir du numéro IDCC (4 chiffres) ou de saisir directement le nom dans le champ de saisie. Lorsqu\'aucune convention collective n\'est applicable, saisir 0001.'
        "
      >
      </lib-sirc-icon>
    </div>

    <mat-form-field appearance="outline" class="container-fluid">
      <mat-label>Identifiant (IDCC) - Intitulé de la convention</mat-label>

      <input
        type="text"
        matInput
        formControlName="conventionCollective"
        [matAutocomplete]="auto"
        required
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayConventionCollective"
      >
        <mat-option
          *ngFor="
            let conventionCollective of filteredListConventionCollective | async
          "
          [value]="conventionCollective"
        >
          {{ conventionCollective.codeIdcc }} -
          {{ conventionCollective.intitule }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="salarieConventionCollective.hasError('required')">
        <p>Ce champ est obligatoire</p>
      </mat-error>
      <mat-error
        *ngIf="
          !salarieConventionCollective.hasError('required') &&
          salarieConventionCollective.hasError('emptyCodeIdcc')
        "
      >
        <p>Valeur invalide, veuillez sélectionner une des options proposées</p>
      </mat-error>
    </mat-form-field>
  </section>
</form>
