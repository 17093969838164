import { Component, Inject, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { LibHttpDemandeService } from '../../../../public-api';
import { MOIS } from '../../../constants/mois';
import { DemandeSharedService } from '../../../services/shared/demande-shared.service';
import { NumberToWords } from '../../../utils/numberToWords.utils';
import { MapperUtils } from './../../../utils/mapper.utils';

export enum ModeIndemnite {
  SIMULATOR, EDITION
}

@Component({
  selector: 'lib-indemnite',
  templateUrl: './indemnite.component.html',
  styleUrls: ['./indemnite.component.scss'],
})
export class IndemniteComponent implements OnInit {
  public readonly MOIS = MOIS;

  public parentForm: UntypedFormGroup;
  public form: FormGroup;
  public listYears = [];

  public moisAnneeRemuneration = []; // Table to store the label of each month for salary

  public isSalaireDisplayed = false; // Show salary section
  public isResultDisplayed = false; // Show indemnity section

  public isOnBo: boolean; // If it's IntraRC

  public isDownloading = false; // The file is downloading

  public ancienneteMoisValue;
  public ancienneteAnneeValue;

  @ViewChild('ancienneteAnneeSlider') ancienneteAnneeSlider!: ElementRef;
  @ViewChild('ancienneteMoisSlider') ancienneteMoisSlider!: ElementRef;
  @ViewChild('ancienneteAnneeInput') ancienneteAnneeInput!: ElementRef;
  @ViewChild('ancienneteMoisInput') ancienneteMoisInput!: ElementRef;

  @Input()
  public mode = ModeIndemnite.EDITION;

  constructor(
    @Inject('LibHttpDemandeService')
    private readonly demandeService: LibHttpDemandeService,
    private readonly demandeSharedService: DemandeSharedService,
  ) { }

  ngOnInit(): void {
    this.isOnBo = this.demandeService.isOnBo();
    this.parentForm = this.demandeSharedService.form;
    this.form = this.parentForm.get('remuneration') as UntypedFormGroup;
    this.generateArrayOfYears();

    // If the form is valid or disabled, then display all sections
    if ((this.indemnite.value && this.moyenneRemunerationMensuelle.value) || this.form.disabled) {
      this.isSalaireDisplayed = true;
      this.isResultDisplayed = true;
      this.anciennete.disable();
      this.salaire.disable();
      this.results.disable();
      this.callInitialiserTableau();
      this.calculRemunerationMoyenne();
    } else {
      this.form.enable();
    }
  }

  public get nombreChar(): number {
    return this.commentaire.value ? Math.max(10000 - this.commentaire.value.length, 0) : 10000;
  }

  public get isEditionMode(): boolean {
    return this.mode === ModeIndemnite.EDITION;
  }

  public get isSimulatorMode(): boolean {
    return this.mode === ModeIndemnite.SIMULATOR;
  }

  updateAncienneteAnneeInput() : void{
    this.ancienneteAnneeValue = this.ancienneteAnneeSlider.nativeElement.value
    this.ancienneteAnnee.patchValue(this.ancienneteAnneeSlider.nativeElement.value);
  }

  updateAncienneteMonthInput() : void{
    this.ancienneteMoisValue = this.ancienneteMoisSlider.nativeElement.value
    this.ancienneteMois.patchValue(this.ancienneteMoisSlider.nativeElement.value);
  }

  updateAncienneteAnneeSlider() : void{
    this.ancienneteAnneeValue = this.ancienneteAnneeInput.nativeElement.value
    this.ancienneteAnnee.patchValue(this.ancienneteAnneeInput.nativeElement.value);
  }

  updateAncienneteMoisSlider() : void{
    this.ancienneteMoisValue = this.ancienneteMoisInput.nativeElement.value
    this.ancienneteMois.patchValue(this.ancienneteMoisInput.nativeElement.value);
  }

  /**
   * Generate array of years, 9 years ago util now
   */
  generateArrayOfYears(): void {
    const max = new Date().getFullYear();
    const min = max - 8;

    for (let i = max + 1; i >= min; i--) {
      this.listYears.push(i);
    }
  }

  /**
   * Scroll up/down the steps
   */
  scrollFromAncienneteToSalaire(): void {
    this.isSalaireDisplayed = true;
    this.anciennete.disable();
    this.callInitialiserTableau();
  }

  scrollFromSalaireToAnciennete(): void {
    this.isSalaireDisplayed = false;
    this.anciennete.enable();
  }

  scrollFromSalairetoResult(): void {
    this.isResultDisplayed = true;
    this.salaire.disable();
    this.results.enable();
    this.moyenneRemunerationMensuelle.enable();
    this.indemnite.enable();
    
    // this.results.patchValue({
    //   indemnite: '',
    //   moyenneRemunerationMensuelle: '',
    //   comentaire: ''
    //   // No need to provide a value for `anotherField`
    // });

    this.calculRemunerationMoyenne();
  }

  scrollFromResultToSalaire(): void {
    this.isResultDisplayed = false;
    this.salaire.enable();
    this.results.enable();
  }

  /**
   * If the checkbox same salary is checked, then set the same value for all months
   */
  applySameSalaire(value): void {
    this.valeurs.controls.slice(0, +this.nombreMois.value).forEach(control => control.setValue(parseFloat(value).toFixed(2)));
  }

  updateSalaryForm(): void {
    this.valeurs.controls.slice(0, +this.nombreMois.value).forEach(control => control.setValue(''));
  }

  /**
   * Initialize the table of salaries in the following months, 12 max
   */
  callInitialiserTableau(): void {
    this.moisAnneeRemuneration = [];
    let date: moment.Moment = moment(this.moisDernierMois.value + '/01/' + this.anneeDernierMois.value);

    // init data
    this.nombreMois.setValue(Math.min(this.ancienneteAnnee.value * 12 + this.ancienneteMois.value, 12));
    // set salary in no working months to 0 if not the form stay invalid with nombreMois < 12
    if (+this.nombreMois.value < 12) {
      this.valeurs.controls.slice(+this.nombreMois.value, 12).forEach(control => control.setValue(0));
    }
    // To refresh if change anciennete without touch the salary input
    if (this.isIdentique.value === true) {
      // get input with name '1' it is the control for same salary input
      const annualSalary = this.valeurs.controls[1].value;
      this.valeurs.controls.slice(0, +this.nombreMois.value).forEach(control => control.setValue(annualSalary));
    }

    for (let i = 0; i <  +this.nombreMois.value; i++) {
      this.initialiserTableauMoisRemuneration(date.month() + 1, date.year());
      date = date.subtract(1, 'months');
    }
    this.moisAnneeRemuneration = this.moisAnneeRemuneration.slice().reverse();
  }

  initialiserTableauMoisRemuneration(mois: number, annee: number): void {
    const moisIntitule = this.MOIS.find((val) => {
      return val.valeur === mois;
    });
    this.moisAnneeRemuneration.push({ mois: moisIntitule.nom, annee });
  }

  /**
   * Calculate the average of salary monthly
   */
  calculRemunerationMoyenne(): void {

    let moyen12 = 0;         // Average of the last 12 monthly salaries
    let moyen3 = 0;         // Average of the last 3 monthly salaries
    const maxNombreMois = +this.nombreMois.value > 12 ? 12 : +this.nombreMois.value;

    // SPEC 5.4 : a) Moyenne des 12 derniers salaires mensuels(intégrant éventuellement la prime exceptionnelle)
    // M1 = Somme(S1 ; Sm) /m = M1
    moyen12 = this.calculMoyen(this.valeurs.controls.slice(0, maxNombreMois).map(control => +control.value));

    moyen3 = this.calculMoyen(this.valeurs.controls.slice(maxNombreMois - 3, maxNombreMois).map(control => +control.value));

    // SPEC 5.4 : b) Moyenne des 3 derniers salaires mensuels,
    // sachant que la prime exceptionnelle à caractère annuel versée sur cette période ne doit êtreprise en compte qu’au prorata
    // (d’où la nécessité de l’identifier sur une ligne séparée pour permettre ce calcul)
    // M2 = (S(m-2) + S(m-1) + S(m)) / 3 – P / 3 + P / m
    moyen3 = moyen3 - this.prime.value / 3 + this.prime.value / maxNombreMois;
    this.moyenneRemunerationMensuelleAutomatique.setValue(+(!moyen3 || moyen12 > moyen3 ? moyen12 : moyen3).toFixed(2));

    // Calcul indemnité Legale
    this.calculIndemniteLegale();
  }

  /**
   * Calculate the average of array
   */
  calculMoyen(arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0) / arr.length;
  }

  /**
   * Calculate the legal indemnity
   * SPEC 5.4 : L’indemnité de licenciement ne peut être inférieure à :
   *            Un quart de mois de salaire par année d’ancienneté pour les années jusqu’à 10 ans ;
   *            Un tiers de mois de salaire par année d’ancienneté pour les années à partir de 10 ans.
   *            SI (Ancienneté<=10)
   *            I = 1/4*(A+ m/12)*SM
   *            SINON
   *            I = 1/4*(A+m/12)*SM + 1/12* (A-10+m/12)*SM
   */
  calculIndemniteLegale(): void {
    let indemnite: number;

    const annee = +this.ancienneteAnnee.value;
    const mois = +this.ancienneteMois.value;
    if (annee < 10 || (annee === 10 && mois === 0)) {
      indemnite = 0.25 * (annee + mois / 12) * this.moyenneRemunerationMensuelleAutomatique.value;
    } else {
      indemnite = (0.25 * (annee + mois / 12) * this.moyenneRemunerationMensuelleAutomatique.value) +
        ((1 / 12) * (annee - 10 + mois / 12) * this.moyenneRemunerationMensuelleAutomatique.value);
    }

    this.indemniteLegale.setValue(Number(indemnite.toFixed(2)));

  }

  /**
   * Displays the proposed compensation "In letters"
   *
   */
  getIndemniteEnLettres(): string {
    if (this.indemnite.value && (this.indemnite.valid || this.indemnite.disabled)) {
      return NumberToWords.convNumberLetter(this.indemnite.value);
    }
    return '-';
  }

  formatValue(element: MatInput): void {
    element.value = parseFloat(element.value).toFixed(2);
  }

  lengthMoisAnneeRemuneration(): number {
    return this.moisAnneeRemuneration.length - 1;
  }

  demandeTelechargerIndemConv(): void {
    this.isDownloading = true;

    const demande = MapperUtils.mapDemande(this.parentForm);

    this.demandeService.downloadFichierConventionCollective(
      this.conventionCollective.value ? this.conventionCollective.value.codeIdcc : '',
      demande.remuneration
    ).subscribe((blob) => {
        FileSaver.saveAs(blob, `aide-indemnites-${this.conventionCollective.value ? this.conventionCollective.value.codeIdcc : 'cc'}.xls`);
        this.isDownloading = false;
      });
  }

  get downloadLinkDisabled(): boolean {
    return this.isDownloading || this.anciennete.invalid || this.moisDernierMois.invalid || this.anneeDernierMois.invalid;
  }

  ///// FORMULAIRE - GETTERS
  get anciennete(): FormControl { return this.form.get('anciennete') as FormControl; }
  get nombreMois(): FormControl { return this.anciennete.get('nombreMois') as FormControl; }
  get ancienneteMois(): FormControl { return this.anciennete.get('ancienneteMois') as FormControl; }
  get ancienneteAnnee(): FormControl { return this.anciennete.get('ancienneteAnnee') as FormControl; }
  get moisDernierMois(): FormControl { return this.anciennete.get('moisDernierMois') as FormControl; }
  get anneeDernierMois(): FormControl { return this.anciennete.get('anneeDernierMois') as FormControl; }

  get salaire(): FormControl { return this.form.get('salaire') as FormControl; }
  get isIdentique(): FormControl { return this.salaire.get('isIdentique') as FormControl; }
  get valeurs(): FormArray { return this.salaire.get('valeurs') as FormArray; }
  get prime(): FormControl { return this.salaire.get('prime') as FormControl; }

  get results(): FormControl { return this.form.get('results') as FormControl; }
  get moyenneRemunerationMensuelle(): FormControl { return this.results.get('moyenneRemunerationMensuelle') as FormControl; }
  get moyenneRemunerationMensuelleAutomatique(): FormControl {
    return this.results.get('moyenneRemunerationMensuelleAutomatique') as FormControl;
  }
  get indemnite(): FormControl { return this.results.get('indemnite') as FormControl; }
  
  get indemniteLegale(): FormControl { return this.results.get('indemniteLegale') as FormControl; }
  get indemniteConventionnelle(): FormControl { return this.results.get('indemniteConventionnelle') as FormControl; }
  get commentaire(): FormControl { return this.form.get('commentaire') as FormControl; }

  get conventionCollective(): FormControl { return this.parentForm.get('salarie').get('conventionCollective') as FormControl; }

  get isMoyenneRemunerationMensuelleLowerThanAuto(): boolean {
    return (this.moyenneRemunerationMensuelle.value && this.moyenneRemunerationMensuelleAutomatique.value
      && this.moyenneRemunerationMensuelle.value < this.moyenneRemunerationMensuelleAutomatique.value);
  }
  get isIndemnityLowerThanLegal(): boolean {
    return (this.indemnite.value && this.indemniteLegale.value && this.indemnite.value < this.indemniteLegale.value);
  }
  get isIndemnityLowerThanConventional(): boolean {
    return (this.indemnite.value && this.indemniteConventionnelle.value && this.indemnite.value < this.indemniteConventionnelle.value);
  }
}
