import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Remuneration } from '../../models/remuneration.model';

export class AncienneteFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private remuneration: Remuneration,
    private isDisabled: boolean,
  ) { }

  build(): UntypedFormGroup {
    return this.fb.group({
      nombreMois: this.fb.control({
        value: this.remuneration.anciennete,
        disabled: this.isDisabled
      }),

      ancienneteMois: this.fb.control({
        value: this.remuneration.anciennete % 12,
        disabled: this.isDisabled
      },
        [Validators.required, Validators.min(0), Validators.max(11), Validators.pattern(/^[0-9]*$/)]
      ),

      ancienneteAnnee: this.fb.control({
        value: Math.floor(this.remuneration.anciennete / 12),
        disabled: this.isDisabled
      },
        [Validators.required, Validators.min(0), Validators.max(60), Validators.pattern(/^[0-9]*$/)]
      ),

      moisDernierMois: this.fb.control({
        value: this.remuneration.moisDernierMois,
        disabled: this.isDisabled
      },
        [Validators.required]
      ),

      anneeDernierMois: this.fb.control({
        value: this.remuneration.anneeDernierMois,
        disabled: this.isDisabled
      },
        [Validators.required]
      ),
    });
  }
}
