import { STATUT } from '../constants/referentiel/statut.enum';

export class Statut {
  id: number;
  code: STATUT;
  intitule: string;

  constructor(
    id: number = 0,
    code: STATUT = null,
    inititule: string = '',
  ) {
    this.id = id;
    this.code = code;
    this.intitule = inititule;
  }
}


