import { Adresse } from './adresse.model';
import { DepartementOrgaMedDto } from './departement-orga-med.model';

export class OrganismeMiniDto {
  id: number;
  uuid: string;
  nomCourt: string;
  nom: string;
  departement: DepartementOrgaMedDto;
  adresse: Adresse;
  telephone: string;

  constructor({
    id = null,
    uuid = '',
    nomCourt = '',
    nom = '',
    departement = new DepartementOrgaMedDto({}),
    adresse = new Adresse({}),
    telephone = '',
  }) {
    this.id = id;
    this.uuid = uuid;
    this.nom = nom;
    this.nomCourt = nomCourt;
    this.departement = departement;
    this.adresse = adresse;
    this.telephone = telephone;
  }
}
