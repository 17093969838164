import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  BreadcrumbState,
  Demande,
  DemandeSharedService,
  ModeIndemnite,
} from 'sirc-lib';
import { DemandeFormBuilder } from '../../form/builder/demande-form.builder';
import { BreadcrumbSharedService } from '../../services/breadcrumb.service';
import { TitleService, TitleState } from '../../shared/service/title.service';

@Component({
  selector: 'app-simulateur-indemnite',
  templateUrl: './simulateur-indemnite.component.html',
})
export class SimulateurIndemniteComponent implements OnInit, AfterViewInit {
  public readonly ModeIndeminte = ModeIndemnite;

  // Formulaire
  public modeleForm: UntypedFormGroup;

  constructor(
    private readonly titleService: TitleService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly demandeSharedService: DemandeSharedService,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: BreadcrumbSharedService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    // Set form to indeminte step
    this.demandeSharedService.demande = new Demande({});
    this.demandeSharedService.form = new DemandeFormBuilder(
      this.formBuilder,
      new Demande({}),
      false
    ).build();

    this.breadcrumbSharedService.setState(BreadcrumbState.SIMULATOR_INDEMNITY);

    this.titleService.changeTitle(TitleState.SIMULATOR_INDEMNITY);
  }

  ngAfterViewInit() {
    this.loadScript();
  }

  loadScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://code.travail.gouv.fr/widget.js';
    script.defer = true;

    this.renderer.appendChild(document.body, script);
  }
}
