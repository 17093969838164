<p>Le mot de passe doit contenir :</p>
<ul>
  <li>
    <mat-icon
      mat-list-icon
      aria-hidden="true"
      [ngClass]="!init ? (hasEightCharacters ? 'ok' : 'ko') : ''"
    >
      {{ init ? "pending" : hasEightCharacters ? "check_circle" : "error" }}
    </mat-icon>
    au moins 8 caractères
  </li>
  <li>
    <mat-icon
      mat-list-icon
      aria-hidden="true"
      [ngClass]="!init ? (hasUpper ? 'ok' : 'ko') : ''"
    >
      {{ init ? "pending" : hasUpper ? "check_circle" : "error" }}
    </mat-icon>
    au moins une lettre majuscule
  </li>
  <li>
    <mat-icon
      mat-list-icon
      aria-hidden="true"
      [ngClass]="!init ? (hasLower ? 'ok' : 'ko') : ''"
    >
      {{ init ? "pending" : hasLower ? "check_circle" : "error" }}
    </mat-icon>
    au moins une lettre minuscule
  </li>
  <li>
    <mat-icon
      mat-list-icon
      aria-hidden="true"
      [ngClass]="!init ? (hasNumber ? 'ok' : 'ko') : ''"
    >
      {{ init ? "pending" : hasNumber ? "check_circle" : "error" }}
    </mat-icon>
    au moins un chiffre
  </li>
  <li>
    <mat-icon
      mat-list-icon
      aria-hidden="true"
      [ngClass]="!init ? (hasSpecialChar ? 'ok' : 'ko') : ''"
    >
      {{ init ? "pending" : hasSpecialChar ? "check_circle" : "error" }}
    </mat-icon>
    au moins un caractère spécial (! &#64; # $ % £ € § + - * = \ /)
  </li>
</ul>
