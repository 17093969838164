import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BreadcrumbState, ETAPE, STATUT, TOAST_SOURCE, TYPE_TOAST } from 'sirc-lib';
import { isNumeroDemande } from '../../directives/validators/numero-demande.validators';
import { isNotBlank } from '../../directives/validators/string-not-blank.validator';
import { DemandeSuiviDto } from '../../modeles/dto/demande-suivi-dto.model';
import { BreadcrumbSharedService } from '../../services/breadcrumb.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DemandeService } from '../../services/crud/demande.service';
import { TitleService, TitleState } from '../../shared/service/title.service';
import {SUIVI} from "../../../../../sirc-lib/src/lib/constants/referentiel/suivi.enum";

@Component({
  selector: 'app-suivi-homologation',
  templateUrl: './suivi-homologation.component.html',
  styleUrls: ['./suivi-homologation.component.scss']
})
export class SuiviHomologationComponent implements OnInit {
  public donnerAvisUrl = this.configService.getEnvironment().donnerAvisUrl;

  public form;

  public demandeRecuperee: DemandeSuiviDto;

  public searching = false;

  public downloadingEmployer = false;
  public downloadingEmployee = false;

  constructor(
    private readonly titleService: TitleService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toasterService: ToasterSharedService,
    private readonly demandeService: DemandeService,
    private readonly configService: ConfigurationService,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: BreadcrumbSharedService,
  ) { }

  public ngOnInit(): void {
    this.breadcrumbSharedService.setState(BreadcrumbState.FOLLOW);

    this.titleService.changeTitle(TitleState.DEMANDE_FOLLOW);

    this.form = this.formBuilder.group({
      numero: ['', [Validators.required, Validators.maxLength(20), isNumeroDemande()]],
      info: ['', [Validators.required, Validators.maxLength(250), isNotBlank()]],
    });

    const infosDemande = this.route.snapshot.params.demande;
    // OUVERTURE DE LA PAGE EN MODE RACCOURCI (en provenance d'un mail par exemple)
    if (infosDemande) {
      this.searching = true;
      this.demandeService.getDemandeSuiviOnIntraPass(infosDemande)
        .pipe(catchError(() => of(null)))
        .subscribe((demandSuivi: DemandeSuiviDto) => {
          // Demande trouvée dans l'Intranet, on affiche les infos de la demande
          // Si on ne la trouve pas, on fait comme si de rien n'était.
          if (demandSuivi) {
            this.demandeRecuperee = demandSuivi;
          }
          this.searching = false;
        });
    }
  }

  public get numeroCtrl(): UntypedFormControl {
    return this.form.get('numero') as UntypedFormControl;
  }

  public get infoCtrl(): UntypedFormControl {
    return this.form.get('info') as UntypedFormControl;
  }

  public get statutClass(): string {
    if (!this.demandeRecuperee || !this.demandeRecuperee.statutCode || !this.demandeRecuperee.etapeCode) {
      return '';
    }

    const etapeCode = this.demandeRecuperee.etapeCode;
    const statutCode = this.demandeRecuperee.statutCode;

    if (etapeCode === ETAPE.ETP_AIN_CODE) {
      return 'in-progress';
    } else if (statutCode === STATUT.STT_REF_CODE || statutCode === STATUT.STT_IRR_CODE) {
      return 'declined';
    } else {
      return 'ok';
    }
  }

  public get statutLabel(): string {
    if (!this.demandeRecuperee || !this.demandeRecuperee.statutCode || !this.demandeRecuperee.etapeCode) {
      return '';
    }

    const etapeCode = this.demandeRecuperee.etapeCode;
    const statutCode = this.demandeRecuperee.statutCode;
    const suiviCode = this.demandeRecuperee.suiviCode;

    // 3 SUVI possible for Etape AIN (A instruire): Recevable, Consultée, En cours d’instruction, En cours de traitement
    if (etapeCode === ETAPE.ETP_AIN_CODE && (suiviCode === null || suiviCode.length === 0)) {
      return 'Demande Reçue';
    } else if (etapeCode === ETAPE.ETP_AIN_CODE && suiviCode === SUIVI.SUI_CONS_CODE) {
      return 'Demande Consultée';
    }
    else if (etapeCode === ETAPE.ETP_AIN_CODE && suiviCode === SUIVI.SUI_INS_CODE) {
      return 'En cours d’instruction';
    } else if (etapeCode === ETAPE.ETP_AIN_CODE && suiviCode === SUIVI.SUI_TRAT_CODE) {
      return 'En cours de traitement';
    }
    // 5 status possible for Etape INS (Instruite) : Refusée, Irrecevable, Validée, Accordée "exprès", Accordée "implicite" – manuel,Accordée "implicite" – automatique
    else if (statutCode === STATUT.STT_REF_CODE) {
      return 'Refusée';
    } else if (statutCode === STATUT.STT_IRR_CODE) {
      return 'Irrecevable';
    } else if (statutCode === STATUT.STT_ACCE_CODE) {
      return 'Validée';
    } else if (statutCode === STATUT.STT_ACCIA_CODE || statutCode === STATUT.STT_ACCIM_CODE) {
      return 'Accord Implicite';
    }
  }

  public get notDownloadable(): boolean {
    if (!this.demandeRecuperee || !this.demandeRecuperee.dateFinDelaiInstruction || !this.demandeRecuperee.statutCode
      || !this.demandeRecuperee.etapeCode) {
      return true;
    }

    const now = moment(new Date());
    const dateFinDelaiInstruction = moment(this.demandeRecuperee.dateFinDelaiInstruction);

    return now.isSameOrBefore(dateFinDelaiInstruction, 'day') || this.demandeRecuperee.etapeCode !== ETAPE.ETP_CLO_CODE ||
      (
        this.demandeRecuperee.statutCode !== STATUT.STT_ACCIA_CODE &&
        this.demandeRecuperee.statutCode !== STATUT.STT_ACCIM_CODE &&
        this.demandeRecuperee.statutCode !== STATUT.STT_ACCE_CODE
      );
  }

  public searchDemand(): void {
    if (this.form.invalid) {
      return;
    }

    this.form.disable();

    this.searching = true;
    this.demandeService.getDemandeSuiviOnIntra(this.numeroCtrl.value.trim(), this.infoCtrl.value.trim())
      .pipe(catchError(() => of(null)))
      .subscribe((demand) => {
        if (demand) {
          // Demand found with intraRC, show data
          this.demandeRecuperee = demand;
          this.searching = false;
        } else {
          // Demand not found, check in téléRC if it exists
          this.demandeService.getDemandeSuiviOnPublic(this.numeroCtrl.value.trim(), this.infoCtrl.value.trim())
            .subscribe({
              next: (demandeExists) => {
                if (demandeExists) {
                  // Demand found in téléRC
                  this.titleService.changeTitle({ value: 'Demande non réceptionnée - ' + TitleState.DEMANDE_FOLLOW.value });
                  this.toasterService.create(
                    TOAST_SOURCE.TELE,{
                    type: TYPE_TOAST.AVERTISSEMENT,
                    body: 'La demande n\'a pas encore été réceptionnée par les services de l\'État. Veuillez réessayer ultérieurement.',
                  });
                } else {
                  // Demand does not exist
                  this.titleService.changeTitle({ value: 'Demande introuvable - ' + TitleState.DEMANDE_FOLLOW.value });
                  this.toasterService.create(
                    TOAST_SOURCE.TELE,{
                    type: TYPE_TOAST.AVERTISSEMENT,
                    title: 'Demande introuvable',
                    body: 'Aucune demande ne correspond aux informations que vous avez renseignées. Veuillez vérifier votre saisie et réessayer.',
                  });
                }

                this.reinitForm(false);
                this.searching = false;
              },
              error: () => {
                this.reinitForm(false);
                this.searching = false;
                this.titleService.changeTitle({ value: TitleState.SERVER_ERROR.value + TitleState.DEMANDE_FOLLOW.value });
                this.toasterService.create(
                  TOAST_SOURCE.TELE,{
                  type: TYPE_TOAST.ERREUR,
                  body: 'Une erreur est survenue pendant la recherche. Veuillez réessayer dans quelque instant.',
                });
              }
            });
        }
      });
  }

  public openAnotherDemand(): void {
    this.titleService.changeTitle(TitleState.DEMANDE_FOLLOW);
    this.reinitForm(true);
  }

  public downloadAttestation(employer: boolean): void {
    if (this.notDownloadable) {
      return;
    }

    // Vérification si hors-délai (+6 mois après clôture)
    const now: moment.Moment = moment(new Date());
    if (now.isAfter(moment(this.demandeRecuperee.dateCloture).add(6, 'month'), 'day')) {
      this.toasterService.create(
        TOAST_SOURCE.TELE,{
        type: TYPE_TOAST.AVERTISSEMENT,
        body: 'Compte tenu des délais, vous ne pouvez plus télécharger l\'attestation d\'homologation. Veuillez contacter votre unité départementale pour l\'obtenir.',
      });

      return;
    }

    if (this.demandeRecuperee.uuid) {
      if (employer) {
        this.downloadingEmployer = true;
      } else {
        this.downloadingEmployee = true;
      }

      this.demandeService.downloadAttestations(this.demandeRecuperee.uuid, employer).subscribe({
        next: (blob) => {
          FileSaver.saveAs(blob, 'attestation-' + (employer ? 'employeur' : 'salarie') + '.pdf');

          if (employer) {
            this.downloadingEmployer = false;
          } else {
            this.downloadingEmployee = false;
          }
        },
        error: () => {
          if (employer) {
            this.downloadingEmployer = false;
          } else {
            this.downloadingEmployee = false;
          }
          this.titleService.changeTitle({ value: TitleState.SERVER_ERROR.value + TitleState.DEMANDE_FOLLOW.value  });
          this.toasterService.create(
            TOAST_SOURCE.TELE,{
            type: TYPE_TOAST.ERREUR,
            body: 'Une erreur est survenue lors du téléchargement de l\'attestation.',
          });
        }
      });
    }
  }

  private reinitForm(clearChamps: boolean): void {
    if (clearChamps) {
      this.form.reset();
    }

    this.form.enable();

    this.demandeRecuperee = null;
    this.downloadingEmployer = false;
    this.downloadingEmployee = false;
  }
}
