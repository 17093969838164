import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { CODE_MESSAGE_INFO, CODE_PARAMETRES, MessageInfo, Parametre } from 'sirc-lib';
import { MessageInfoService } from '../services/crud/message-info.service';
import { ParametresService } from '../services/crud/parametre.service';

@Injectable({
  providedIn: 'root',
})
export class ParametreResolver  {
  constructor(
    private parametresService: ParametresService,
    private messageInfoService: MessageInfoService,
  ) { }

  resolve(): Observable<[Parametre, MessageInfo]> {
    return forkJoin([
      this.parametresService.getByCode(CODE_PARAMETRES.CODE_P_LIEN_PDF_SALARIE_PROTEGE),
      this.messageInfoService.getByCode(CODE_MESSAGE_INFO.CODE_MI_TELERC_ACCUEIL)
    ]);
  }
}
