export class Ape {
  id: number;
  code: string;
  intitule: string;
  public constructor({
    id = null,
    code = '',
    intitule = '',
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;

  }
}
