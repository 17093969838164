<div
  class="dialog-edit-demand"
  role="dialog"
  aria-modal="true"
  aria-labelledby="dialog-title"
>
  <div class="dialog-header">
    <h4 mat-dialog-title id="dialog-title">
      {{ title }}
    </h4>
    <button
      type="button"
      mat-flat-button
      mat-dialog-close
      aria-label="Fermer"
      title="Fermer la fenêtre modale"
      data-dismiss="dialog"
      class="close-button"
    >
      <mat-icon class="cross-icon" aria-hidden="true">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <ng-container *ngIf="!demandeNumber">
      <p>
        <span *ngIf="edit; else transmit"
          >Afin de poursuivre votre saisie,</span
        >
        <ng-template #transmit
          >Afin de transmettre votre demande à l'administration,</ng-template
        >
        veuillez saisir le courriel de déclarant, le numéro associés à votre
        demande ainsi que le mot de passe utilisé lors de la création.
      </p>
    </ng-container>

    <form id="cnxForm" [formGroup]="passwordForgot ? passwordForm : editForm">
      <p class="hint-form">* les champs sont obligatoires</p>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Courriel du déclarant</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="Ex. courriel@domaine.com"
            required
            autocomplete="username"
          />
          <mat-hint
            ><p>
              Adresse courriel utilisée lors de la création de la demande
            </p></mat-hint
          >

          <mat-error *ngIf="emailCtrl.hasError('required')">
            <p>Ce champ est obligatoire</p>
          </mat-error>
          <mat-error *ngIf="emailCtrl.hasError('maxlength')">
            <p>Taille maximale : 250 caractères</p>
          </mat-error>
          <mat-error *ngIf="emailCtrl.hasError('email')">
            <p>
              Veuillez saisir une adresse courriel au format
              adresse&#64;domaine.fr
            </p>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field *ngIf="!demandeNumber" appearance="outline">
          <mat-label>Numéro de la demande</mat-label>

          <input
            type="text"
            matInput
            formControlName="numero"
            placeholder="Ex: 201901000012P"
            mask="000000000000A"
            [patterns]="patternsNumeroDemand"
            required
          />

          <mat-error *ngIf="numeroCtrl.hasError('required')">
            <p>Ce champ est obligatoire</p>
          </mat-error>
          <mat-error *ngIf="numeroCtrl.hasError('maxlength')">
            <p>Taille maximale : 20 caractères</p>
          </mat-error>
          <mat-error *ngIf="numeroCtrl.hasError('isNumeroDemandePublic')">
            <p>
              Veuillez respecter le format du numéro de demande : année et mois
              de création, suivis de 6 chiffres et de la lettre "p" (majuscule
              ou minuscule)
            </p>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field *ngIf="!passwordForgot" appearance="outline">
          <mat-label>Mot de passe</mat-label>

          <input
            class="mat-mdc-form-field-input-control"
            matInput
            formControlName="password"
            autocomplete="current-password"
            [type]="hide ? 'password' : 'text'"
            required
          />

          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="hide = !hide"
            [attr.aria-label]="
              hide ? 'Afficher le mot de passe' : 'Masquer le mot de passe'
            "
            [attr.aria-pressed]="hide"
          >
            <mat-icon aria-hidden="true">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>

          <mat-error *ngIf="passwordCtrl.hasError('required')">
            <p>Ce champ est obligatoire</p>
          </mat-error>
        </mat-form-field>

        <ngx-recaptcha2
          #captcha
          *ngIf="passwordForgot && captchaSiteKey"
          formControlName="recaptcha"
          [siteKey]="captchaSiteKey"
          [hl]="'fr'"
          [useGlobalDomain]="false"
        >
        </ngx-recaptcha2>

        <button
          *ngIf="!demandeNumber"
          class="mt-1 mat-primary"
          type="button"
          style="letter-spacing: -0.2px"
          mat-button
          (click)="passwordForgot = !passwordForgot"
          [attr.aria-label]="
            passwordForgot
              ? 'Retourner à l\'écran de connexion'
              : 'Retrouver mon mot de passe oublié'
          "
        >
          {{
            passwordForgot ? "Revenir à la connexion" : "Mot de passe oublié"
          }}
        </button>
      </div>
    </form>

    <!--Actions-->
    <mat-dialog-actions>
      <button
        *ngIf="passwordForgot; else cnxButtonTemplate"
        class="me-1 mat-primary"
        type="submit"
        form="cnxForm"
        mat-button
        [disabled]="passwordForm.invalid || isSubmitting"
        (click)="submitNewPassword()"
        aria-label="Boutton pour demande de modification de mot de passe d'une demande"
      >
        Demande de changement de mot de passe
      </button>

      <ng-template #cnxButtonTemplate>
        <button
          #cnxButton
          class="me-1"
          type="submit"
          form="cnxForm"
          mat-button
          [disabled]="editForm.invalid || isSubmitting"
          (click)="submitEditDemande()"
          aria-label="Boutton pour valider l'édition d'une demande"
        >
          Connexion à la demande
        </button>
      </ng-template>

      <button
        mat-button
        class="mat-primary"
        aria-label="Boutton pour annuler l'édition d'une demande'"
        mat-dialog-close
      >
        Annuler
      </button>
    </mat-dialog-actions>
  </div>
</div>
