import { Adresse } from './adresse.model';
import { Departement } from './departement.model';
import { JourChome } from './jour-chome.model';
import { Signature } from './signature.model';

export class Organisme {
  id: number;
  uuid: string;
  nomCourt: string;
  nom: string;
  infosService: string;
  infosComplementaires: string;
  notificationTeletransmission: boolean;
  telephone: string;
  fax: string;
  telephoneService: string;
  adresse: Adresse;
  courriel: string;
  departement: Departement;

  enfants: Organisme[];

  joursChomes: JourChome[];

  signatures?: Signature[] = [];

  parentId: number;

  public constructor({
    id = null,
    uuid = '',
    nomCourt = '',
    nom = '',
    infosService = '',
    infosComplementaires = '',
    notificationTeletransmission = true,
    telephone = '',
    fax = '',
    telephoneService = '',
    adresse = new Adresse({}),
    courriel = '',
    departement = new Departement({}),
    enfants = [],
    joursChomes = [],
    signatures = null,
    parentId = null,
  }) {
    this.id = id;
    this.uuid = uuid;
    this.nomCourt = nomCourt;
    this.nom = nom;
    this.infosService = infosService;
    this.infosComplementaires = infosComplementaires;
    this.notificationTeletransmission = notificationTeletransmission;
    this.telephone = telephone;
    this.fax = fax;
    this.telephoneService = telephoneService;
    this.adresse = adresse ? new Adresse(adresse) : new Adresse({});
    this.courriel = courriel;
    this.departement = departement;
    this.enfants = enfants;
    this.joursChomes = joursChomes;
    this.signatures = signatures;
    this.parentId = parentId;
  }
}
