<div
  class="dialog-create-demand"
  role="dialog"
  aria-modal="true"
  aria-labelledby="dialog-title"
>
  <div class="dialog-header">
    <h4 id="dialog-title" class="d-flex align-items-center" mat-dialog-title>
      Création d'une demande
    </h4>
    <button
      type="button"
      mat-flat-button
      mat-dialog-close
      aria-label="Fermer"
      title="Fermer la fenêtre modale"
      data-dismiss="dialog"
      class="close-button"
    >
      <mat-icon class="cross-icon" aria-hidden="true">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <!--Protected worker-->
    <div id="protected-worker" class="alert alert-warning">
      <p>
        Si la rupture conventionnelle concerne un
        <b>salarié "protégé"</b> (délégué du personnel, délégué syndical, membre
        élu du CE, etc.), vous ne pouvez pas utiliser ce service.<br />
        Une procédure d'autorisation auprès de l'inspection du travail est
        prévue par la loi.
      </p>

      <p class="bold">
        Vous devez télécharger le formulaire spécifique de demande
        d'autorisation d'une rupture conventionnelle d'un salarié protégé (CERFA
        n°14599*01) ci dessous.
      </p>

      <a
        id="download"
        [href]="urlPdfSalProtect"
        target="_blank"
        aria-label="Télécharger le formulaire dédié au salarié protégé (nouvelle fenêtre)"
      >
        <mat-icon aria-hidden="true">picture_as_pdf</mat-icon>
        Télécharger le formulaire dédié au salarié protégé
      </a>
    </div>

    <!-- Create new demand -->
    <div id="new-worker">
      <p class="mt-1 custom-p">
        Afin de pouvoir procéder, veillez renseigner une adresse valide pour
        créer une demande.
      </p>

      <form *ngIf="!isRequestSent; else messageSend" [formGroup]="createForm">
        <mat-form-field appearance="outline" class="email">
          <mat-label class="bold me-1">Courriel du déclarant : </mat-label>

          <input
            matInput
            type="email"
            placeholder="Ex. courriel@domaine.com"
            formControlName="email"
            autocomplete="username"
          />

          <mat-error *ngIf="emailFormControl.hasError('email')">
            <p>
              Veuillez saisir une adresse courriel au format
              adresse&#64;domaine.fr
            </p>
          </mat-error>
          <mat-error
            *ngIf="
              emailFormControl.touched && emailFormControl.hasError('required')
            "
          >
            <p>Ce champ est obligatoire</p>
          </mat-error>
        </mat-form-field>

        <div class="mt-1">
          <ngx-recaptcha2
            *ngIf="captchaSiteKey"
            formControlName="recaptcha"
            [siteKey]="captchaSiteKey"
            [hl]="'fr'"
            [useGlobalDomain]="false"
          >
          </ngx-recaptcha2>
        </div>
      </form>

      <ng-template #messageSend>
        <div class="alert alert-info italic" role="alert">
          <p>Un mail de création d'une demande vous a été envoyé.</p>
          <p>
            Vous vous apprêtez à réaliser une demande de création d'un dossier
            de rupture conventionnelle.
          </p>
          <p>
            Si vous poursuivez, vous recevrez un mail de confirmation de
            création de cette demande. Vous disposerez alors d'un jour
            calendaire pour confirmer votre demande et débuter la saisie. Passé
            ce délai, la demande de création sera annulée.
          </p>
        </div>
      </ng-template>
    </div>

    <!--Actions-->
    <mat-dialog-actions>
      <div *ngIf="!isRequestSent; else warnings">
        <button
          mat-button
          class="me-1"
          [ngClass]="{
            'button-inactive': createForm.invalid || isSubmitting,
            'button-active': createForm.valid && !isSubmitting
          }"
          [disabled]="createForm.invalid || isSubmitting"
          (click)="submitCreationDemand()"
        >
          Envoyer le Courriel de création
        </button>
        <button
          mat-button
          class="mat-primary"
          aria-label="Annuler la création d'une demande"
          mat-dialog-close
        >
          Annuler
        </button>
      </div>

      <ng-template #warnings>
        <button
          mat-button
          class="mat-primary"
          aria-label="Fermer la fenêtre"
          mat-dialog-close
          (click)="isRequestSent = false"
        >
          Fermer
        </button>
      </ng-template>
    </mat-dialog-actions>
  </div>
</div>
