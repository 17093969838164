import { SafeHtml } from '@angular/platform-browser';

export class Article {
  public id: number;
  public contenu: string;
  public safeContenu: SafeHtml;
  public titre: string;
  public ordre: number;
  public type: string;

  constructor({
    id = null,
    contenu = '',
    titre = '',
    ordre = -1,
    type = ''
  }) {
    this.id = id;
    this.titre = titre;
    this.contenu = contenu;
    this.ordre = ordre;
    this.type = type;
  }
}
