export class Parametre {
  id: number;
  code: string;
  intitule: string;
  valeur: string;

  constructor({
    id = null,
    code = '',
    intitule = '',
    valeur = '',
  }) {
    this.id = id;
    this.code = code;
    this.intitule = intitule;
    this.valeur = valeur;
  }
}
