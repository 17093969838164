import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  private readonly focusableElement = 'button, a, input, select, textarea';
  public focus = false;
  private fragment: string;
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
    
    this.subscription = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      this.setFocusToElement();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setFocusToElement(): void {
    if (!this.fragment) {
      return;
    }

    try {
      const bloc = document.querySelector<HTMLElement>(`#${this.fragment}`);
      const focusable = bloc.querySelectorAll<HTMLElement>(this.focusableElement);

      if (focusable && focusable.length > 0) {
        focusable[0].focus();
      } else {
        bloc.focus();
      }
    } catch (e) { }
  }
}
