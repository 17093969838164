import { ETAPE } from 'sirc-lib';
export class Etape {
  id: number;
  code: ETAPE;
  intitule: string;

  constructor({
    id = null,
    code = null,
    intitule = '',
  }) {
    this.id = id;
    this.intitule = intitule;
    this.code = code;
  }
}


