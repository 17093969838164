import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Remuneration } from './../../models/remuneration.model';
import { AncienneteFormBuilder } from './anciennete-form.builder';
import { SalaireFormBuilder } from './salaire-form.builder';
import { ResultsFormBuilder } from './results-form.builder';

export class RemunerationFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private remuneration: Remuneration,
    private isDisabled: boolean,
  ) { }

  build(): UntypedFormGroup {
    return this.fb.group({
      id: this.fb.control({
        value: this.remuneration.id,
        disabled: true,
      }),

      anciennete: new AncienneteFormBuilder(this.fb, this.remuneration, this.isDisabled).build(),

      salaire: new SalaireFormBuilder(this.fb, this.remuneration, this.isDisabled).build(),
      
      results: new ResultsFormBuilder(this.fb, this.remuneration, this.isDisabled).build(),

      commentaire: this.fb.control({
        value: this.remuneration.commentaire,
        disabled: this.isDisabled
      },
        [Validators.maxLength(10000)]
      ),
      
    });
  }
}
