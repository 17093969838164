import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Remuneration } from '../../models/remuneration.model';

export class ResultsFormBuilder {
  constructor(
    private fb: UntypedFormBuilder,
    private remuneration: Remuneration,
    private isDisabled: boolean
  ) {}

  build(): UntypedFormGroup {
    return this.fb.group({
      moyenneRemunerationMensuelle: this.fb.control(
        {
          value: this.remuneration.moyenneRemunerationMensuelle,
          disabled: this.isDisabled,
        },
        {
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/),
          ],
          updateOn: 'change',
        }
      ),

      moyenneRemunerationMensuelleAutomatique: this.fb.control({
        value: 0,
        disabled: this.isDisabled,
      }),

      indemnite: this.fb.control(
        {
          value: this.remuneration.indemnite,
          disabled: this.isDisabled,
        },
        {
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/),
          ],
          updateOn: 'change',
        }
      ),

      indemniteLegale: this.fb.control(
        {
          value: this.remuneration.indemniteLegale,
          disabled: this.isDisabled,
        },
        [
          Validators.min(0),
          Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/),
        ]
      ),

      indemniteConventionnelle: this.fb.control(
        {
          value: this.remuneration.indemniteConventionnelle,
          disabled: this.isDisabled,
        },
        [
          Validators.min(0),
          Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/),
        ]
      ),
    });
  }
}
