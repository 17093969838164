export class JourChome {
  id: number;
  code: string;
  actif: boolean;
  intitule: string;
  jour: number;
  mois: number;
  national: boolean;
  systeme: boolean;

  public constructor({
    id = null,
    code = '',
    actif = true,
    intitule = '',
    jour = null,
    mois = null,
    national = true,
    systeme = true,
  }) {
    this.id = id;
    this.code = code;
    this.actif = actif;
    this.intitule = intitule;
    this.jour = jour;
    this.mois = mois;
    this.national = national;
    this.systeme = systeme;
  }
}
